.card_virtual {
  box-shadow: grey;
}

.profile {
  // margin-left: 16px;
  // margin-top: 16px;
  background-color: "#FFF";
  width: 100%;
  margin: 0px auto;
  max-width: 95%;
  padding: 15px 0px;
}
.ant-select.ant-select-status-success.select_multiplequarytol.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search
  .ant-select-selector
  span.ant-select-selection-item {
  display: flex;
  align-items: center;
  width: 100%;
}
p.title_changes.font {
  color: #0e0e0e;
  font-weight: bold;
  text-align: center;
}
.red-text {
  color: red !important;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  width: 95%;
  // margin-top: 10px;
  // margin-bottom: 10px;
  // margin-left: 35px;
  margin: 0px auto;
}

.icon-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 25px;
  width: 25px;
}

.icon-bg {
  background-color: #eff1f3;
  color: grey;
  border-radius: 25px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.icon-text {
  font-size: 12px;
  color: grey;
}

.loader-con {
  min-height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  width: 95%;
  color: #c6cacc;
}

// .detail-heading {
//   font-size: 16px;
//   font-weight: 600;
//   color: #8d9ca6;
//   font-family: "Roboto";
//   margin-bottom: -2px;
//   font-family: 'Montserrat', sans-serif;
// }
.detail-heading {
  font-size: 16px;
  font-weight: 600;
  color: #666666;
  font-family: "Montserrat", sans-serif;
  margin-bottom: -2px;
  // white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.detail-ans {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  // width: 100%;
  text-align: left;
  margin-bottom: 0px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.detail-tag-ans {
  margin-top: 3px;
  background-color: #e3e7ea;
  color: #586370;
  font-size: 12px;
  font-weight: 600;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  border-radius: 6px;
}

.inputs-container {
  width: 100%;
  margin-top: 22px;
  border: 1px solid #edf0ee;
  background-color: #ffffff;
  box-shadow: grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.quick-msg {
  display: flex;
  align-self: flex-start;
  // margin-left: 25px;
  // margin-top: 14px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin: 15px auto;
  width: 95%;
}

.quickmsgbox {
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  max-width: 95%;
  width: 100%;
}

.select {
  width: 90%;
  height: 35px;
  margin-bottom: 6px;
}

.tab_text {
  display: flex;
}

.invoice-details {
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
}

.item {
  font-size: 16px;
  color: #706f6d;
  font-family: "Montserrat", sans-serif;
}

.status-paid {
  background-color: #ccedce;
  color: #55ad59;
  width: 45px;
  text-align: center;
  border-radius: 3px;
  height: 22px;
}

.status-pending {
  background-color: #f2d1b8;
  color: #de8540;
  min-width: 45px;
  text-align: center;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
  height: 22px;
}

.three-dots-button {
  background: transparent;
  border: none;
}

.doc-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;
  margin-bottom: 6px;
}

.doc-date {
  font-size: 16px;
  margin-top: 10px;
  color: #706f6d;
  margin-left: -300px;
  font-family: "Montserrat", sans-serif;
}

.doc-name {
  font-size: 16px;
  margin-top: 10px;
  color: #706f6d;
  margin-left: 12px;
  font-family: "Montserrat", sans-serif;
}

.doc-icon {
  color: #b3b2af;
  height: 35px;
  width: 35px;
  margin-top: -4px;
  margin-left: 12px;
}

.three-dots-button-doc {
  background: transparent;
  border: none;
  margin-right: 25px;
  height: 35px;
  width: 35px;
  color: #706f6d;
}

.textarea {
  width: 100%;
  margin-bottom: 14px;
}

.add {
  margin-left: 16px;
  font-family: "Montserrat", sans-serif;
}

.notes-heading {
  margin-left: 16px;
  margin-top: 30px;
  font-size: 22px;
  color: #525150;
  // margin-bottom: -10px;
  font-family: "Montserrat", sans-serif;
}

.notes-container {
  margin-left: 8px;
  width: 100%;
  padding: 20px;
  margin-top: -5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  font-family: "Montserrat", sans-serif;
}

.notes-para {
  color: #706f6d;
  margin-bottom: 10px;
  font-size: 15 px;
  font-family: "Montserrat", sans-serif;
}

.edit-del-button {
  margin-top: -45px;
  border: none;
  background: transparent;
}

.edit-del-icon {
  height: 20px;
  width: 20px;
  color: #706f6d;
}

.edit-icon {
  margin-right: -25px;
}

.date {
  margin-top: 4px;
  color: #9e9d9b;
  font-family: "Montserrat", sans-serif;
}

.quote-details {
  justify-content: space-between !important;
  font-family: "Montserrat", sans-serif;
}

.quote {
  margin-left: 25px;
  font-family: "Montserrat", sans-serif;
}

.email-container {
  flex-direction: column !important;
  margin-top: -16px;
  font-family: "Montserrat", sans-serif;
}

.email-text-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.email-date {
  margin-right: 22px;
  font-family: "Montserrat", sans-serif;
}

.email-heading {
  // margin-left: 20px;
  color: #525150;
  // margin-top: 23px;
  // margin-bottom: -10px;
  // font-size: 16px;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 20px;
}

.call-icon {
  color: green;
}

.call-fail-icon {
  color: red;
}

.lead_ButtonStatus {
  height: 30px;
  border: 1px solid rgb(177, 167, 230);
  border-radius: 5px;
  display: flex;
  margin-right: 0px;
  font-size: 14px;
  font-weight: 500;
}

.lead_detailmap {
  height: 45vh;
  margin-bottom: 20px;
  position: relative;
  z-index: +1;
}
.companydetail_map {
  height: 742px;
  margin-bottom: 20px;
  position: relative;
  z-index: +1;
}
.spaceitemulti .ant-space-item:first-child {
  width: 90%;
}

@keyframes glowing {
  // 0% {
  //   background-color: #f2b38e;
  //   box-shadow: 0 0 5px #323332;
  // }
  50% {
    // background-color: #c6e1be;
    background-color: #f2b38e;
    box-shadow: 0 0 5px #323332;
  }

  // 100% {
  //  background-color: #ef9560;
  //   box-shadow: 0 0 5px #2ba805;
  // }
}

.button {
  animation: glowing 1300ms infinite;
}

p.detail-heading.responsive_text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;

  @media (max-width: 1600px) {
    padding-top: 4%;
  }
}

p.detail-heading.responsive_text span {
  max-width: max-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 5px !important;
  min-width: 60px;
  justify-content: center;
}
.typematerials {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  flex-flow: nowrap;
  white-space: nowrap;
}
.mapbox-1view h5.detail-heading-quote {
  width: 100%;
  min-width: 140px;
}
.typematerials1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  padding-left: 10px;
}
.mapbox-1view p.align-vendors {
  width: 100%;
  max-width: 155px;
}
.typematerials .itemmaterial {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: baseline;
  justify-content: left;
  width: 100%;
  max-width: 180px;
}
.typematerials .itemtruckType {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: baseline;
  justify-content: left;
  width: 100%;
  @media (min-width: 375px) and (max-width: 1200px) {
    margin: 5px;
  }
}

span.materialtxtggs {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.approveLimit {
  &:hover {
    border-color: green !important;
    background-color: green !important;
    color: #fff !important;
  }
}

.closeLimit {
  &:hover {
    border-color: red !important;
    background-color: red !important;
    color: #fff !important;
  }
}

.table_span {
  text-transform: capitalize;
  color: #474747;
  font-size: 16px;
  font-family: sans-serif;
  cursor: pointer;
}

.switch_customPrice{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 24px;
  margin-top: -16px;
  align-items: center;
  margin-bottom: 5px;
}