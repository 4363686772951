.admin-login-form {
  background-image: url(../Images/LoginBg1.png);
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: column;

  > .ant-row {
    width: 250px;
  }
}

.login-fields {
  // margin: 0px auto;
  // margin-left: 175px;
  width: 100%;
  height: auto;
  padding: 35px;
  // background: #ffffff 0% 0% no-repeat padding-box;
  // background: #0000005e;
  background: rgb(0 0 0 / 56%);
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 10px;
  max-width: 500px;

  @media (max-width: 500px) {
    max-width: 90%;
  }
}

.login-form-button {
  background-color: #949caa;
  border-color: #949caa;
  border-radius: 15px;
  float: right;
  //   font-family: avenirmedium;
  font-size: 15px;
  height: 35px;
  width: 100%;

  &:hover {
    border-color: #949caa;
    background-color: #949caa;
  }
}

.login-form-forgot {
  color: #ffffff;
  font-size: 14px;
  float: right;
}

.password-row {
  margin-top: 12px;
}

.signUp-fields {
  margin: 0px auto;
  width: 100%;
  height: auto;
  padding: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 10px;
  max-width: 500px;

  @media (max-width: 500px) {
    max-width: 90%;
  }
}

.form-input {
  height: 40px;
  border-radius: 5px;
  padding: 4px 11px;
  width: 100% !important;
}

.ant-input-number {
  height: 40px;
  border-radius: 5px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  // border-color: #d9d9d9 !important;
}

.select_Height .ant-select-selection-item {
  width: 140px;
  font-size: 16px;
}

.select_Height.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.select_Height.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2.5px !important;
}

.margin_bottom {
  margin-bottom: 5px;
}

.myprofile .ant-form-item-label label {
  font-size: 16px !important;
}

.profilesetting .ant-form-item-label label {
  font-size: 16px !important;
}

.iconprifleview {
  font-size: 20px;
  max-width: 60px;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.iconprifleview a,
.iconprifleview span {
  font-size: 100%;
  color: #969eac;
}

.stauscontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  margin: 0px auto;
  max-width: 100%;
  align-content: center;
  line-height: normal;
}

.stauscontent p {
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #7d7d7d;
  width: 100%;
}

.stauscontent span {
  margin: 5px 0px;
  font-size: 18px;
  font-weight: 600;
  color: #222;
  width: 100%;
}

.socialmedia {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
}

.socialmedia a {
  margin: 5px 0px;
  font-size: 20px;
  font-weight: 600;
  color: #949caa;
  width: 100%;
  max-width: max-content;
}
.ant-table-cell,
.col_styling,
.ant-table-cell-fix-right,
.ant-table-cell-fix-right-first {
  right: 0px !important;
}
