.card_virtual.ant-card-bordered {
  box-shadow: 0px 10px 20px #0000000d;
}

.card_virtual .ant-card-head-title {
  color: #232323;
  opacity: 1;
  letter-spacing: 0px;
  padding: 10px 0px 10px;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50px;
}
.ant-input-number.accounts_payable {
  background-color: transparent;
}

.customer_Lead {
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  height: 45px;
  border-top: 0px;
  padding: 10px;
}

.customer_leadHeading {
  font-size: 16px;
  font-weight: 600;
  color: #495057;
  padding: 8px !important;
}

.customer_Lead_data {
  border-left: 2px solid #fff;
  border-top: 0px;
}

.lead_Name {
  font-size: 15px;
  color: #4e555c;
  //   font-family: 'Montserrat', sans-serif;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.lead_Email {
  font-size: 12px;
  color: #c1cbd1;
  font-family: "Montserrat", sans-serif;
  //   font-family: system-ui;
  font-weight: 400;
}

.col_style_quarry {
  color: #5a6066;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.form-input {
  height: 40px;
  border-radius: 5px;
  padding: 4px 11px;
}

.react-grid-item.cssTransforms {
  border-radius: 5px !important;
  // height: 125px !important;
}

.grid_Name {
  font-size: 15px;
  color: #575e64;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.grid_Email {
  font-size: 14px;
  color: #bac5cc;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
  margin-top: -3px;
}

.grid_Company {
  font-size: 16px;
  // color: #96a7b2;
  color: #5f666c;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.grid_Status {
  text-align: right;
  margin-top: 5px;
  font-size: 14px;
  color: #96a7b2;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

// .btn_styling {
//    background-color: grey;
// }
// .col_styling{
//   width: 45%;
// }

.quarrytool_modal.mapquarry .ant-drawer-content-wrapper {
  width: 450px !important;
  @media (min-width: 375px) and (max-width: 766px) {
    width: 320px !important;
  }
}

.quarrytool_modal.mapquarry .ant-drawer-header {
  background-color: #586370;
}

.quarrytool_modal.mapquarry .ant-drawer-header button.ant-drawer-close {
  color: white;
}

.quarrytool_modal.mapquarry .ant-drawer-header .ant-drawer-title {
  color: white;
}

// .quarrytool_modal.mapquarry .ant-drawer-body form#Quarry span.title_changes {
//   font-size: 14px;
//   color: #999;
// }
// .quarrytool_modal.mapquarry .ant-drawer-body form#Quarry input {
//   padding-left: 0px;
//   padding-right: 0px;
//   height: auto;
// }
// .quarrytool_modal.mapquarry .ant-drawer-body form#Quarry input.ant-input-number.numberictest {
//   border: none;
// }

// span.title_changes.quarryitem {
//   font-size: 14px;
//   color: #999;
// }
.quarryheight_map,
.quarryheight_tool {
  height: 75vh;
  position: relative;
  z-index: +1;
}

.loop-hrline {
  padding: 5px 0px;
  border-bottom: 1px dashed #ddd;
}

.loop-hrline:last-child {
  border-bottom: none;
}
.gridbtntoolDispatch{
  margin-left: 180px;

}
.gridbtntool {
  margin: 0px 5px;
  align-items: center;
  display: flex;

  @media (max-width: 440px) {
    margin: 5px 3px;
  }
}

.gridbtntool-owner-operator {
  margin: 0px 5px;
  align-items: center;
  display: flex;

  @media (max-width: 440px) {
    margin: 5px 0px;
  }
  @media (min-width: 300px) and (max-width: 375px) {
    margin: 5px 0px !important;
  }
}

.quarrytoolgrid {
  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
}

.quarrytooltable .ant-table-body table {
  @media (max-width: 1024px) {
    width: 100% !important;
    min-width: 1030px !important;
  }
}

.select_multiplequarytol .ant-select-selector {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(217, 217, 217) !important;
  border-radius: 5px !important;
  min-height: 40px;
}

.ant-col.quarryheding {
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.flxgrp {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}
.flxgrp .headingleft {
  font-size: 16px;
  font-weight: 400;
  color: #8d9ca6;
  font-family: "Montserrat", sans-serif;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.flxgrp .paratext {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  font-family: "Montserrat", sans-serif;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  max-width: 281px;
  justify-content: left;
  width: 100%;
  margin: 5px 0px;
}
.paraedit {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin: 0px 0px;
}
.paraedit p {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 5px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
}
.teamparaedit {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  color: #586370;
  justify-content: left;
  font-size: 12px;
}
.teamparaedit span {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  align-items: center;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}
.teamparaedit span::before {
  content: "\2022";
  margin: 0px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.textarea-box {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
  align-items: center;
  justify-content: left;
  position: relative;
}
.flxgrp .paratext:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}
.flxgrp1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}
.flxgrp1 .headingleft {
  font-size: 16px;
  font-weight: 400;
  color: #8d9ca6;
  font-family: "Montserrat", sans-serif;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.flxgrp1 .paratext {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  font-family: "Montserrat", sans-serif;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  max-width: 220px;
  justify-content: left;
  width: 100%;
  margin: 5px 0px;
}
.flxgrp1 .paratext:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}

.quarryProfile_Collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.material_addBtn {
  border-radius: 5px;
  height: 40px;
  background: rgb(255, 255, 255);
  width: 25%;
  border: 1px solid rgb(217, 217, 217);
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.select_arrow_none .ant-select-arrow {
  display: none;
}

.ant-popover-inner-content {
  padding: 5px 8px !important;
}

.span_edit {
  color: black;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  height: auto;
}

.btn_edit {
  font-size: 15px;
  cursor: pointer;
  border: none;
  padding: 0px;
  text-align: left;
  width: 100%;
  padding: 5px;

  &:hover {
    border-color: #949caa !important;
    background-color: #949caa !important;
    .span_edit {
      color: #fff !important;
    }
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.downloadIcon {
  color: #30363d;
  font-size: 16px;
  // padding: 16px 32px;
  cursor: pointer;
}

.spin_quarry {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.upload_Delete_Icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border: 0px;
  height: 50px;
  box-shadow: none !important;
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: #fff !important;
  }
}

.Radio_btn .ant-radio {
  border: 1px solid #586370 !important;
  border-radius: 50px !important;
}

.table_bottom_style {
  margin-bottom: 5px;
}

// .deleteImageBtn {
//   position: absolute;
//   z-index: 1;
//   width: 100%;
//   display: flex;
//   justify-content: right;
//   top: 0px;
//   right: 12px;
//   cursor: pointer;
// }

.deleteImageBtn {
  position: absolute;
  z-index: 1;
  width: 90%;
  display: flex;
  justify-content: space-between;
  top: 0px;
  right: 12px;
  cursor: pointer;
}
