body {
  overflow-x: hidden;
}

.side-menu ul {
  height: 90vh;
}

.remove_border .ant-modal-footer {
  border: 0px !important;
}

.side-menu ul li ul {
  height: auto;
}

.ant-card-meta-description {
  margin-top: -40px !important;
  text-align: left;
}

.ant-card-meta-title {
  margin-bottom: 0px !important;
  margin-top: -10px !important;
}
.ant-card.ant-card-bordered.export_class.page {
  margin: 0px auto;
  max-width: 1214px;
}
.ant-table-content.ant-table.invoicetablennd.ant-table
  table
  tfoot.ant-table-tfoot
  tr
  td
  h4
  b {
  min-width: 121px !important;
  display: inline-block;
  text-align: left;
}
.ant-table-content.ant-table.invoicetablennd.ant-table
  table
  tfoot.ant-table-tfoot
  tr
  td
  h4
  span {
  min-width: 112px !important;
  display: inline-block;
  text-align: left;
}
.ant-table-content.ant-table.invoicetablennd.ant-table
  table
  tfoot.ant-table-tfoot
  tr
  td
  h4
  b::after {
  content: ":";
  float: right;
  margin-right: 15px;
}
.ant-table-content.ant-table.invoicetablennd.ant-table
  table
  thead.ant-table-thead.rows-col
  tr
  th {
  background-color: #333 !important;
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
}
.ant-table-content.ant-table.invoicetablennd.ant-table
  table
  tfoot.ant-table-tfoot
  tr
  td {
  background-color: transparent !important;
  color: #333;
  font-weight: 600;
}
span.line1-text {
  width: 100%;
  display: block;
}
tbody.ant-table-tbody.child-odd tr:last-child td {
  border-bottom: 2px solid #333 !important;
}
tbody.ant-table-tbody.child-odd tr td {
  padding: 5px 10px;
  height: 32px;
}
tbody.ant-table-tbody.child-odd tr:nth-child(odd) {
  background-color: #dedede;
}

span.brdtxtline-rightside {
  border-bottom: 1px solid black;
  display: block;
  float: right;
  min-width: 126px;
  min-height: 27px;
  margin-left: 13px;
  margin-top: -1px;
  font-size: 13px;
}
.fullpage-print-width {
  display: table;
  width: 100%;
  text-align: center;
}
.gridchoice-box1 {
  display: table-cell;
  float: right;
  width: 50%;
  text-align: right;
}
.grid-box-choice {
  display: table;
  width: 100%;
  text-align: center;
}
.gridchoice-box {
  display: table-cell;
  float: left;
  width: 50%;
  text-align: left;
}
.grid-box-imgsys {
  display: table-cell;
  width: 285px;
  text-align: center;
  float: left;
}
.grid-box-middle {
  display: table-cell;
  width: 210px;
  margin: 0px 20px;
  text-align: left;
  padding: 0px 15px;
  box-sizing: border-box;
}
.grid-box-last {
  display: table-cell;
  text-align: left;
  width: 250px;
  float: right;
}
span.brdbottom-line {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  min-height: 28px;
  align-items: center;
}
span.brdbottom-line.top {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  min-height: 28px;
  align-items: center;
  float: left;
  white-space: nowrap;
  // color: red;
  // font-weight: bold;
}
span.thank-you {
  font-size: 24px;
  font-weight: bold;
  margin: 0px 24px;
}
.ant-form-item-explain-error {
  color: #ff4d4f;
  text-transform: capitalize;
}

input[type="checkbox"].cardradio-intbtn {
  accent-color: red;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;

  transition: 0.2s all linear;
  cursor: pointer;
}

.checkBox_btn {
  width: 25px;
  accent-color: red;
  position: absolute;
  top: 10px;
  z-index: 1000;
  left: 5px;
  height: 20px;
  border: 2px solid red;
}

input[type="checkbox"].cardradio-intbtn:checked {
  accent-color: red;
  border: 6px solid red;
  outline: unset !important;
}

button.ant-btn.ant-btn-primary.login-form-button {
  overflow: hidden;
}

.quickleadsection.show-on-desktop-portrait button.ant-btn.ant-btn-primary span {
  max-width: max-content;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #586370;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e9ecef;
}

.ant-tabs-tab:hover {
  color: #586370 !important;
}

.ant-pagination-item-active:hover {
  border-color: #586370;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #586370;
  border-color: #586370;
}

.ant-input:focus,
.ant-input-focused,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #586370;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 0%);
  border-right-width: 1px;
  outline: 0;
}

.ant-input-number:hover {
  border-color: #586370;
  border-right-width: 1px;
}

.ant-btn {
  border-color: #586370;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #586370 !important;
}
span.title_changes_name {
  color: #fff;
  margin-left: 7px;
}
.bordered-class {
  padding: 10px;
  // border: 2px solid black;
  margin: 10px 0px;
}

.ant-checkbox-checked::after {
  border: 1px solid #586370;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #586370 !important;
  pointer-events: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #586370;
  border-right-width: 1px;
}

.ant-row.ant-form-item.multi_field {
  margin-bottom: 10px;
  margin-top: 5px;
}

.ant-input:hover {
  border-color: #586370;
  border-right-width: 1px;
}
.ant-btn > span {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  // width: 100%;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
  @media (min-height: 600px) and (max-height: 675px) {
    margin-right: 0px !important;
    font-size: 12px !important;
  }
  @media (min-height: 676px) and (max-height: 750px) {
    margin-right: 0px !important;
    font-size: 14px !important;
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  // border-color: #586370;
  box-shadow: 0 0 0 2px rgb(88, 99, 112, 0);
  border-right-width: 1px;
  outline: 0;
}

.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #586370;
}

.ant-picker-focused {
  border-color: #586370;
  box-shadow: 0 0 0 2px rgb(88, 99, 112, 0);
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #586370;
  border-right-width: 1px;
}

.css-1pahdxg-control {
  border-color: #586370 !important;
  box-shadow: 0 0 0 1px rgb(88, 99, 112, 0) !important;
}

.ant-layout {
  background: #fafafa !important;
}

a.linkreturn {
  max-width: max-content;
}

textarea:hover,
textarea:focus {
  border-color: #586370 !important;
  box-shadow: 0 0 0 1px rgb(88, 99, 112, 0) !important;
}

.ant-modal {
  @media (max-width: 767px) {
    width: 90% !important;
  }
}

// .ant-drawer-footer button {
//   background-color: #e9ecef !important;
// }
.ant-drawer-content .ant-drawer-footer button {
  background-color: #586370 !important;
  color: white !important;
}

.addfield_btn {
  height: 40px;
  border-radius: 3px;
  border-color: #d6d6d6;
}

.ant-drawer-content .ant-drawer-footer button.cancelbtnd {
  background-color: transparent !important;
  color: #586370 !important;
  border-color: #586370 !important;
}
// button.rec.rec-arrow.rec {
//   position: absolute;
//   z-index: +9;
// }
// button.rec.rec-arrow.rec.rec-arrow-left {
//   left: 30px;
// }
// button.rec.rec-arrow.rec.rec-arrow-right {
//   right: 30px;
// }

button.ant-btn.ant-btn-default.button_Crm {
  color: #586370 !important;
  border-color: #d9d9d9;
}

button.ant-btn.ant-btn-default.button_Crm span {
  color: #586370 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #586370;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgb(250 250 250 / 0%);
  border-color: #586370;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #586370;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #586370;
}

.ant-pagination-item-active a {
  color: #586370;
}

.ant-pagination-item-active:hover a {
  color: #586370;
}

.ant-pagination-item:hover a {
  color: #586370;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  // color: #1890ff;
  color: #586370;
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  // color: #1890ff;
  color: #586370;
}

.logo-header {
  object-fit: fill;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #fff;
  // @media (min-width: 993px) and(max-width:1024px) {
  //   width: 110%;
  // }
  // @media (min-width: 100px) and(max-width:499px) {
  //   width: 75%;
  // }
  // @media (min-width: 500px) and(max-width:767px) {
  //   width: 70%;
  // }
  // @media (min-width: 768px) and(max-width:991px) {
  //   width: 69%;
  // }
}

.side-menu {
  // box-shadow: 0px 10px 20px rgb(225 225 225) !important;
  overflow: auto !important;
  height: 100% !important;
  position: sticky !important;
  left: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
  background: white !important;
  // border-right: 1px solid rgb(225, 225, 225) !important;
  z-index: +9 !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  left: 0;
  padding: 8px calc(50% - 26px / 2);
  text-overflow: clip;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu
  > .ant-layout-sider-children
  > ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-inline-collapsed
  a
  > .max_sidebarlogo {
  display: none;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger.side-menu
  > .ant-layout-sider-children
  > ul.ant-menu.ant-menu-root.ant-menu-inline
  > a
  > .min_sidebarlogo {
  display: none;
}

.min_sidebarlogo img.logo-header {
  max-width: 23px;
}

.max_sidebarlogo img.logo-header {
  max-width: 220px;
}
body .ant-input-number-affix-wrapper-disabled {
  background-color: #fafafa !important;
  color: rgb(0 0 0 / 60%);
}

body
  .ant-input-number.ant-input-number-readonly.material.ant-input-number-disabled.ant-input-number-readonly,
body
  .ant-input-number.ant-input-number-readonly.ant-input-number-disabled.ant-input-number-readonly {
  background-color: #fafafa !important;
  color: rgb(0 0 0 / 60%);
}

// .ant-layout-sider-trigger {
//   position: fixed;
//   bottom: 0;
//   z-index: 1;
//   height: 48px;
//   color: #949caa;
//   line-height: 48px;
//   text-align: center;
//   background: #e6f7ff;
//   cursor: pointer;
//   transition: all 0.2s;
// }
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: white;
  line-height: 48px;
  text-align: center;
  background: rgb(88, 99, 112, 0.7);
  cursor: pointer;
  transition: all 0.2s;
  @media (max-width: 1199px) {
    // display: none;
  }
}

.ant-btn-primary {
  color: #fff;
  border-color: #586370;
  background: #586370;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #586370;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu
  ~ section.ant-layout.sitecontentlayout
  header.ant-layout-header
  section.ant-layout
  header.ant-layout-header.header_styling {
  max-width: 96% !important;

  @media only screen and (max-width: 1640px) {
    max-width: 95% !important;
  }

  @media only screen and (max-width: 1440px) {
    max-width: 94% !important;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 100% !important;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: #586370;
  border-color: #586370;
  background: #fff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #586370;
  background: #586370;
}

.header_styling {
  z-index: +9 !important;
  width: 100% !important;
  padding: 0px !important;
  height: 80px !important;
  position: fixed !important;
  background: white !important;
  max-width: 86%;

  @media (max-width: 1800px) {
    max-width: 85%;
  }

  @media (max-width: 1600px) {
    max-width: 84%;
  }

  @media (max-width: 1500px) {
    max-width: 83%;
  }

  @media (max-width: 1380px) {
    max-width: 81%;
  }

  @media (max-width: 1240px) {
    max-width: 79%;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    z-index: +9;
  }
}

.header_border {
  border-bottom: 1px solid #e1e1e1;
}

.ant-popover-content {
  margin-top: -15px !important;
}

.ant-popover-arrow {
  margin-top: -15px !important;
}

.select_header {
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  color: #131419 !important;
  font-weight: 600 !important;
}

.select_header .ant-select-selection-item {
  font-weight: 600 !important;
  color: #000 !important;
}

.option_style {
  font-weight: 500 !important;
}

.ant-select-arrow {
  font-size: 13px !important;
  font-weight: bolder !important;
  color: black !important;
}

.menuitem {
  // color: #7b8086;
  color: #989da2;
  // font-size: 16px;
  font-weight: 500;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.menuitem_lin {
  color: #495057;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.title_changes {
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #000;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
  @media (min-width: 320px) and (max-width: 450px) and (orientation: portrait) {
    font-size: 14px;
  }
  @media (min-width: 480px) and (max-width: 668px) and (orientation: landscape) {
    font-size: 14px;
  }
  @media (width: 1024px) {
    font-size: 14px;
  }
}

.title_new {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding-right: 8%;
}

@media screen and (max-width: 1200px) {
  .material {
    margin-bottom: 3vh;
  }
}

.profilecontentdd {
  // float: right;
  padding: 10px;
  height: auto;
  // margin-left: 1%;
  margin-left: auto;

  a {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: left;

    @media (max-width: 991px) {
      .ant-card-meta .ant-card-meta-detail {
        display: none;
      }
    }
  }

  span.anticon.anticon-down {
    align-content: center;
    justify-content: center;
    display: flex;
    height: 10px;
    flex-wrap: wrap;
    margin: 13px 5px;
    color: #525252;
    font-size: 100%;
    padding-right: 10px;

    @media (max-width: 991px) {
      align-content: center;
      justify-content: center;
      display: flex;
      height: 100%;
      flex-wrap: wrap;
      margin: auto 0px;
    }
    @media (max-width: 599px) {
      display: none !important;
    }
  }

  @media (min-width: 375px) and (max-width: 599px) and (orientation: portrait) {
    right: -14% !important;
    bottom: -18px;
    position: absolute;
    z-index: +99;
  }
}

.fixedprofile {
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  max-width: max-content;
  // min-width: 500px;
  min-width: -webkit-fill-available;
  width: max-content;
  justify-content: right;
  height: 100%;

  @media (max-width: 991px) {
    min-width: 100%;
  }

  @media (max-width: 767px) {
    justify-content: left;
  }
}

.fixedprofilegrpff {
  display: flex;
  flex-flow: nowrap;
  margin-left: auto;
  max-width: max-content;
  width: 100%;
  justify-content: right;
  height: 100%;
  align-items: center;
  align-content: center;

  @media (min-width: 375px) and (max-width: 599px) and (orientation: portrait) {
    display: flex;
    flex-flow: nowrap;
    margin-left: 20px;
    max-width: max-content;
    width: 50%;
    justify-content: right;
    height: 100%;
    align-items: center;
    align-content: center;
  }
}

.pages_heading {
  font-size: 120%;
  font-weight: bold;
  margin-left: 30px;
  margin-bottom: 0px;
  height: 100%;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: normal;

  @media (max-width: 1199px) {
    font-size: 100%;
    margin-left: 0px;
  }

  @media (max-width: 996px) {
    font-size: 98%;
    width: 100%;
  }

  @media (min-width: 375px) and (max-width: 599px) and (orientation: portrait) {
    display: flex;
    flex-flow: nowrap;
    margin-left: 10px;
    max-width: max-content;
    height: 100%;
    align-items: center;
    align-content: center;
    font-size: 100%;
    width: 100%;
  }
}

section.ant-layout.ant-layout-has-sider.fullsitelayout {
  background-color: white !important;
}

// .ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
//   padding-left: 0px !important;
// }

// .ant-tooltip>.ant-tooltip-content>.ant-tooltip-inner a span.menuitem {
//   color: #495057 !important;
// }

// .ant-tooltip>.ant-tooltip-content>.ant-tooltip-arrow>span.ant-tooltip-arrow-content {
//   --antd-arrow-background-color: white !important;
//   display: none;
// }

// .ant-tooltip>.ant-tooltip-content>.ant-tooltip-inner {
//   background-color: white !important;
// }

.ant-table.ant-table-fixed-header.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body {
  overflow: auto !important;
}

.divideverticalicon {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin: 0px 2px;
  color: #b0b5bd;
  font-size: 157%;

  @media (max-width: 767px) {
    display: none;
  }
}

.quickleadsection {
  padding: 10px;
  height: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: right;
  max-width: max-content;
  width: 100%;

  @media (min-width: 375px) and (max-width: 599px) and (orientation: portrait) {
    margin-top: 12px;
    padding: 2px;
  }
}

.bellicon_notification {
  padding: 0px 10px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  justify-content: center;
  line-height: normal;
}

.ant-row.popovergrid {
  max-width: 75px;
  width: 100%;
}

.text_headingpage {
  min-height: 60px;
}

.text_headingpage_mobile {
  @media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
    max-width: 100%;
  }
  @media (min-width: 375px) and (max-width: 736px) and (orientation: landscape) {
    max-width: 100%;
  }
}

// .ant-modal.antmodal_grid {
//   width: 100% !important;
//   // max-width: 520px;

//   // @media (max-width: 767px) {
//   //   max-width: 90%;
//   // }
// }
.ant-table-header table tr th {
  background-color: #e3e7ea !important;
  font-size: 92% !important;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  background-color: #fff !important;
  margin: 0px !important;
  padding: 10px !important;
}

p.parapopup {
  margin: 2px 0px;
  display: flex;
  flex-wrap: nowrap;
  font-weight: 500;
  width: 100%;
  max-width: 100%;
  color: #666;
}

b.para_line {
  // margin-right: 5px;
  color: #000;
  width: 100%;
  max-width: max-content;
}

button {
  box-shadow: none !important;
  outline: none !important;
}

@media (max-width: 1200px) {
  // aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu~section.ant-layout.sitecontentlayout>header>section>header.ant-layout-header.header_styling {
  //   max-width: 95.6% !important;
  // }

  //   .ant-layout-sider-trigger {
  //     position: absolute;
  //     bottom: inherit;
  //     z-index: 1;
  //     height: 48px;
  //     color: #949caa;
  //     line-height: 48px;
  //     text-align: center;
  //     background: #e6f7ff;
  //     cursor: pointer;
  //     transition: all 0.2s;
  //     right: 0px;
  //     top: 0px;
  //     margin: 10px;
  //     max-width: 60px;
  //     width: 100% !important;
  // }
  // .side-menu {
  //   max-width: 100% !important;
  //   min-width: max-content !important;
  //   width: 100% !important;
  //   position: relative !important;
  //   flex: auto !important;
  //   height: auto !important;
  //   padding-bottom: 10px !important;
  // }
  //   aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu > .ant-layout-sider-children > ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-inline-collapsed a > .max_sidebarlogo {
  //     display: flex !important;
  //     margin: 0px 20px !important;
  // }
  // aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger.side-menu > .ant-layout-sider-children > ul.ant-menu.ant-menu-root.ant-menu-inline > a > .max_sidebarlogo {
  //   display: flex !important;
  //   margin: 0px 20px !important;
  // }
  // aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu > .ant-layout-sider-children > ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-inline-collapsed >li.ant-menu-submenu.ant-menu-submenu-vertical.menuitem,aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu > .ant-layout-sider-children > ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-inline-collapsed >li.ant-menu-item{
  //   display: none;
  // }
  // aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu > .ant-layout-sider-children > ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-inline-collapsed a > .min_sidebarlogo {
  //   display: none !important;
  // }
  // section.ant-layout.ant-layout-has-sider.fullsitelayout {
  //   flex-wrap: wrap;
  // }
  // section.ant-layout.sitecontentlayout {
  //   width: 100% !important;
  //   max-width: 100% !important;
  //   margin: 0px !important;
  // }

  .header_styling {
    z-index: +9 !important;
    width: 100% !important;
    padding: 0px !important;
    height: auto !important;
    // position: relative !important;
    background: white !important;
    max-width: 100% !important;
  }

  .mainlayout {
    padding-top: 0px;
  }

  .dark-mode {
    color: "#ffffff";
    //backgroundColor: "#131417",
    background-color: "#2A2A2C";
  }

  .form-input {
    height: 40px;
    border-radius: 5px;
    padding: 4px 11px;
  }

  .header_styling > .ant-row > .ant-col > .ant-row {
    margin: 0px !important;
  }
}

@media (max-width: 1199px) {
  th,
  td {
    padding: 6px 5px !important;
    font-size: 95% !important;
  }
}

@media (max-width: 991px) {
  .ant-modal.antmodal_grid {
    width: 90% !important;
  }
}

@media (max-width: 767px) {
  .mainlayout {
    margin: 10px 15px;
  }
  .ant-col.text_headingpage {
    max-width: max-content;
  }
}

@media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
  .mainlayout {
    margin: 22vw 10px;
  }
}
@media (min-width: 375px) and (max-width: 736px) and (orientation: landscape) {
  .mainlayout {
    margin: 12vw 10px;
  }
}
@media (min-width: 735px) and (max-width: 1200px) {
  .mainlayout {
    margin: 11vw 10px !important;
  }
}

.ant-input-number-handler-wrap {
  display: none !important;
}

span.ant-radio.ant-radio-checked span.ant-radio-inner {
  border-color: #586370 !important;
}

span.ant-radio.ant-radio-checked span.ant-radio-inner::after {
  background-color: #586370 !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #586370 !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}

.ant-modal.truckwidth {
  width: 100% !important;
  max-width: 900px;

  @media only screen and (max-width: 600px) {
    max-width: 80%;
  }
}

.truckcollapse .ant-collapse-extra {
  width: 100%;
}

.chckbox-truck {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.chckbox-truck .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  justify-content: left;
  align-items: center;
  align-content: flex-start;
}

.chckbox-truck label.ant-checkbox-wrapper {
  display: flex;
  flex-wrap: nowrap;
  // max-width: 172px;
  max-width: max-content;
  width: 100%;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
  margin: 5px 0px !important;
  // margin-left: 0px !important;
}

.chckbox-truck label.ant-checkbox-wrapper span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.formrowdd {
  padding: 1px;
}

.ticketarrowgrp {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: left;
}

span.ant-checkbox {
  overflow: inherit !important;
}

.ticketarrowgrp button {
  border-radius: 100%;
  width: 46px;
  height: 46px;
}

.ticketarrowgrp button:hover {
  background-color: white !important;
  border-color: white;
  color: #586370 !important;
}

span.col_style_quarry:empty::before,
span.col_styling:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}

.ant-col.detail-ans:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}

p.detail-ans:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}

.headerdrawer .ant-drawer-header {
  color: white !important;
  background-color: #586370 !important;
}

.headerdrawer .ant-drawer-header button {
  color: white !important;
}

.headerdrawer .ant-drawer-header .ant-drawer-title {
  color: white !important;
}

.antmodal_grid.headermodal .ant-modal-header {
  color: white !important;
  background-color: #586370 !important;
  border-color: #586370 !important;
}

.antmodal_grid.headermodal .ant-modal-title {
  color: white !important;
}

.antmodal_grid.headermodal .ant-modal-content button .ant-modal-close-x {
  color: white !important;
}

::selection {
  color: #fff;
  background: #586370 !important;
}

html {
  --antd-wave-shadow-color: #586370 !important;
  --scroll-bar: 0;
}

span.title_smallchanges {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #666;
}

.editmapc {
  margin: 0px auto;
  max-width: max-content;
}

.loop-hrline span.title_changes.materialadded {
  border-bottom: 1px dashed rgb(221 221 221);
  display: flex;
  flex-wrap: wrap;
}

.loop-hrline span.title_changes.materialadded:last-child {
  border-bottom: none;
}

.grid_Name.locationname {
  font-size: 120%;
}

.grid_Name.namegridss {
  // font-size: 15px;
  font-size: 14px;
  text-transform: capitalize;
}

.scrlchk span.anticon.ant-collapse-arrow {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #586370 !important;
  border-radius: 10px;
}

.materialcategory {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin: 10px 0px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.materialcat {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin: 10px 0px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

// .materialcat div {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: auto;
//   margin-bottom: 3px;
//   max-width: max-content;
//   width: 100%;
// }
.linedashed {
  padding: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  border-bottom: 1px dashed #ddd;
}

.linedashed:last-child {
  border: none;
}

.rc-time-picker input {
  height: 100%;
}
.custom-button {
  display: inline-block;
  padding: 3px 17px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  margin-right: 10px;
}

.custom-button:hover {
  background-color: #e0e0e0;
  border-color: #999;
  color: #000;
}

.custom-button1 {
  display: inline-block;
  padding: 3px 17px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.custom-button1:hover {
  background-color: #e0e0e0;
  border-color: #999;
  color: #000;
}

.custom-button2 {
  display: inline-block;
  padding: 3px 17px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  float: right;
}

.custom-button2:hover {
  background-color: #e0e0e0;
  border-color: #999;
  color: #000;
}

.chechoice .ant-checkbox-inner::after {
  position: absolute;
  top: 0px;
  left: 0px;
  display: table;
  opacity: 1;
  content: "N";
  text-align: center;
  transform: none !important;
  border: none !important;
  padding: 1px 2px;
  background-color: #a61d24;
  color: #fff;
  font-weight: 600;
  width: 100%;
  // height: 100%;
  // font-size: 95% !important;
  // display: flex;
  // align-items: center;
  // align-content: center;
  // justify-content: center;
}

.chechoice .ant-checkbox-checked .ant-checkbox-inner::after {
  transform: none !important;
  border: none !important;
  content: "Y";
  color: #fff;
  background-color: #008000 !important;
  width: 100%;
  height: 100%;
  // font-size: 95% !important;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
}

.chechoice .ant-checkbox-checked::after {
  visibility: inherit !important;
  border: none !important;
  color: #fff !important;
  text-align: center;
  padding: 2px;
  font-weight: 600;
}

.detail-ans a.icon-text-container {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  color: #000;
}

.ant-checkbox-group.chechoice {
  margin-bottom: 5px;
}

.ant-checkbox-group.chechoice
  label.ant-checkbox-wrapper.ant-checkbox-group-item {
  flex-direction: row-reverse;
  justify-content: left;
  font-size: 16px;
}

.ant-checkbox-group.chechoice.mat_choice
  label.ant-checkbox-wrapper.ant-checkbox-group-item {
  width: 100%;
  justify-content: left;
  margin-bottom: 5px;
  max-width: 165px;
}

.ant-checkbox-group.chechoice.mat_choice label.ant-checkbox-wrapper {
  max-width: 50%;
  width: 100%;
  margin: 5px 0px;
  font-size: 15px;
}

.detail-ans a:empty::before {
  content: "--EMPTY--";
  color: rgb(221 221 221);
  cursor: text;
}

.ant-checkbox-group.chechoice
  label.ant-checkbox-wrapper.ant-checkbox-group-item
  span.ant-checkbox {
  margin-left: 10px;
}

.ant-checkbox-group.chechoice
  label.ant-checkbox-wrapper.ant-checkbox-group-item
  span {
  padding: 0px !important;
}

.editflex-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.editflex-content .icon-text-container {
  margin: 0px 10px;
  flex-direction: row-reverse;
}

.editflex-content .icon-text-container p.icon-text {
  margin: 0px 5px;
}

a.hrv-text:hover {
  color: #333 !important;
}

.fontreducer .detail-heading {
  font-size: 16px;
  margin-bottom: 5px;
}

.fontreducer .detail-ans,
.fontreducer .title_changes {
  margin-bottom: 10px;
}

.tiprotitle .title_changes b {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #666666;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.headerdrawer .ant-drawer-content-wrapper {
  width: 378px !important;
  @media (min-width: 375px) and (max-width: 766px) {
    width: 320px !important;
  }
}

.addontext span.ant-input-group-wrapper.form-input {
  padding: 0px;
}

.addontext
  span.ant-input-group-wrapper.form-input
  span.ant-input-wrapper.ant-input-group {
  height: 40px;
}

.addontext
  span.ant-input-group-wrapper.form-input
  span.ant-input-wrapper.ant-input-group
  input {
  height: 100%;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #586370 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: lightgray !important;
}

.ant-picker-today-btn {
  color: #586370 !important;
}

.dragmapcode {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

.dragmapcode span {
  width: 100%;
  display: flex;
  max-width: max-content;
}

a.icon-text-container:empty {
  pointer-events: none;
}

.iconeditdelete {
  display: flex;
  // flex-wrap: wrap;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  gap: 0px 15px;
}

.iconeditdelete span {
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  width: 100%;
}

.materialgrids:empty {
  display: none;
}

.material_gridf {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.material_gridf .loop-hrline {
  max-width: 49%;
  margin: 2px 2px;
}

.material_gridf .loop-hrline:nth-child(2n) {
  border-left: 1px dashed #ddd;
}

.material_gridf .loop-hrline:last-child {
  border-bottom: 1px dashed #ddd;
}

.fontreducer {
  border-bottom: 1px dashed #ddd;
  margin-bottom: 10px;
}

.fontreducer:last-child {
  border: none;
  margin-bottom: 0px;
}

.contactname-multiple {
  position: relative;
}

.ant-modal.antmodal_grid.headermodal.mapcompon_grid {
  width: 100% !important;
  max-width: 900px;

  @media only screen and (max-width: 991px) {
    width: 90% !important;
  }
}

.textfont-route {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  flex-direction: row;
  margin-bottom: 5px;
  border-bottom: 1px dashed #ddd;
}

.textfont-route b {
  margin-right: 5px;
}

.textfont-route p {
  margin: 0;
  font-size: 100%;
  border-left: 1px dashed #ddd;
  padding: 0px 10px;
  min-height: 25px;
  height: 100%;
}

.textfont-route:last-child {
  border-bottom: none;
}

// .commagrid:empty {
//   display: none;
// }

.commagrid {
  float: left;
}

.commagrid::after {
  content: ",";
}

.commagrid:last-child:after {
  content: "";
}

.width-gridw {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.title_changes:empty::before {
  content: "--EMPTY--";
  color: #ddd;
}

.commagrid:empty::before {
  content: "--EMPTY--";
  color: #ddd;
}

.fontreducer1 {
  border-bottom: 1px dashed #ddd;
  margin-bottom: 10px;
}

.linebox_grid {
  border-right: 1px dashed #ddd;
}

.linebox_grid:last-child {
  border-right: none;
}

.emptycontent:empty {
  margin-bottom: 10px;
  border: 1px dashed #ddd;
  min-height: 50px;
}

.emptycontent:empty::before {
  content: "--EMPTY--";
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: #ddd;
}

.linebox_grid p.detail-ans {
  word-break: break-all;
  font-size: 100%;
}

.detail-ans div:empty::before {
  content: "--EMPTY--";
  color: #ddd;
}

.ant-row.owneroster p.detail-ans {
  margin-bottom: 0px;
}

.ant-tooltip-inner a[title] span.menuitem {
  color: white !important;
}

.twistedtable table thead tr th {
  background-color: #e3e7ea !important;
  padding: 5px;
  text-align: center;
  border-bottom: 1px dashed rgba(88, 99, 112, 0.5);
  border-collapse: collapse;
  color: rgb(88 99 112);
  border-right: 1px dashed rgba(88, 99, 112, 0.5);
}

.twistedtable table {
  // border: 1px dashed rgba(88,99,112,0.5 );
  border-collapse: collapse;
}

.twistedtable table tbody tr td {
  border-bottom: 1px dashed rgba(88, 99, 112, 0.5);
  border-collapse: collapse;
  text-align: center;
  padding: 5px;
  border-right: 1px dashed rgba(88, 99, 112, 0.5);
}

.twistedtable table tbody tr td:empty::before {
  content: "--EMPTY--";
  color: rgb(221 221 221);
  pointer-events: none;
}

.twistedtable table thead tr th:last-child {
  border-right: none;
}

.twistedtable table tbody tr:last-child td {
  border-bottom: none;
}

.twistedtable table tbody tr td:last-child {
  border-right: none;
}

.textfont-route p:empty::before {
  content: "--EMPTY--";
  color: rgb(221 221 221);
  pointer-events: none;
}

.scrlchk .ant-collapse-header .ant-collapse-extra span {
  border-bottom: 1px dashed #ddd;
}

.scrlchk .ant-collapse-header .ant-collapse-extra span:last-child {
  border-bottom: none !important;
}

.scrlchk .ant-collapse-header .ant-collapse-extra span p:empty::before {
  content: "--EMPTY--";
  color: rgb(221 221 221);
  pointer-events: none;
}

.maplinegrid {
  // border-bottom: 1px dashed #ddd;

  b {
    padding: 0px 5px;
    font-size: 15px;
    color: #000;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  p {
    // border-left: 1px dashed #ddd;
    padding: 0px 5px;
    font-size: 15px;
    color: #586370;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
}

.maplinegrid:last-child {
  border-bottom: none;
}

.scrlchk
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-extra {
  margin-left: auto;
  line-height: 30px;
}

span.para_linegrp {
  display: flex;
  // flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  align-items: center;
  border-bottom: 1px dashed #ddd;

  p.parapopup {
    max-width: 70%;
    padding: 2px 5px;
    margin: 0px;
    border-left: 1px dashed #ddd;
  }

  b.para_line {
    max-width: 80px;
    padding: 2px 5px;
    margin: 0px;
  }
}

ul.listicon-color {
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  width: 100%;
  max-width: 100%;
}

ul.listicon-color li {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  border-bottom: 1px dashed rgba(88, 99, 112, 0.6);
  margin: 2px 0px;
  padding-bottom: 2px;
}

ul.listicon-color li img {
  max-width: 46px;
  height: auto;
}

ul.listicon-color li p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 10px;
  color: rgb(88, 99, 112);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.listicon-color li:last-child {
  border-bottom: none;
}

.textfont-route.tableroute {
  padding: 0px !important;
}

.textfont-route.tableroute table {
  width: 100%;
  text-align: left;
}

.textfont-route.tableroute table tr th {
  border-bottom: 1px dashed #ddd;
  border-right: 1px dashed #ddd;
  padding: 0px 12px;
}

.textfont-route.tableroute table tr th:last-child {
  border-right: none;
}

.textfont-route.tableroute table tr td {
  // border-bottom: 1px dashed rgb(221 221 221);
  border-right: 1px dashed rgb(221 221 221);
  // padding: 0px 12px;
  border-collapse: collapse;
}

.textfont-route.tableroute table tr td:last-child {
  // border-bottom: none;
  border-right: none;
}

.textfont-route.tableroute table tr td .tablespp span {
  border-top: 1px dashed rgb(221, 221, 221);
  width: 100%;
  display: flex;
  padding: 0px 12px;
}

.listitemweek {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: left;
}

.listitemweek ul {
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin: 10px 0px;
}

.listitemweek ul li {
  padding: 5px;
  background-color: #eaebed;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 13px;
  flex-wrap: wrap;
  margin: 0px 5px;
}

.listitemweeks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: left;
  flex-direction: column;
}

.textfont-route.tableroute table tr td .tablespp span:first-child {
  border-top: none;
}

.tablespp span:empty::before {
  content: "--EMPTY--";
  color: #ddd;
}

span.ant-input-group-addon .ant-select.selectaddon {
  width: 100px;
  color: rgb(0 0 0) !important;
}

.calenderowline {
  border: 1px dashed #ddd;
  padding: 10px;
}

.radiodd {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: left;
  margin: 4px 0px;
}

.radiodd label.ant-radio-wrapper .ant-row {
  align-items: center;
}

.tabstatus {
  margin-top: 40px;
  @media (min-width: 375px) and (max-width: 1025px) {
    margin-top: 60px !important;
  }
}

.ant-tabs.ant-tabs-top.tabversi .ant-tabs-nav {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 100%;
  @media (min-width: 375px) and (max-width: 744px) and (orientation: landscape) {
    position: relative;
    width: 100%;
    max-width: 100%;
  }
  @media (min-width: 375px) and (max-width: 1024px) {
    position: absolute;
    margin-bottom: 20px;
  }
}

.ant-tabs.ant-tabs-top.tabversi .ant-tabs-nav::before {
  border-bottom: none !important;
}

.ant-tabs.ant-tabs-top.tabversi .ant-tabs-nav .ant-tabs-nav-wrap {
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: max-content;
  border-radius: 10px;
  background-color: #e3e7ea;
}

.ant-tabs.ant-tabs-top.tabversi
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-tab.ant-tabs-tab-active {
  // background-color: white;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  font-size: 16px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 13px !important;
  }
}

.ant-tabs.ant-tabs-top.tabversi
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  /* display: none; */
  background-color: white !important;
  height: 50%;
  z-index: -1;
  border-radius: 10px;
  top: 7px;
  padding: 15px;
  box-shadow: 2px 2px 4px #8e97a3;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  /* display: none; */
  background-color: white !important;
  height: 50%;
  z-index: -1;
  border-radius: 10px;
  top: 2px;
  padding: 15px;
  box-shadow: 2px 2px 4px #8e97a3;
}

.ant-tabs.ant-tabs-top.tabversi .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
  width: 100%;
  max-width: max-content;
  margin: 0px auto;
}

.ant-tabs.ant-tabs-top.tabversi
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list {
  margin: 0px !important;
}

td.ant-table-cell.col_style_quarry.col_style_quarry222 tr:empty::before {
  content: "--EMPTY--";
  color: #ddd;
}

// .ant-table-thead>tr>th,
// .ant-table-tbody>tr>td,
// .ant-table tfoot>tr>th,
// .ant-table tfoot>tr>td {
//   white-space: nowrap;
// }

.col_quarrytype::after {
  content: ",";
  margin: 0px 1px;
}

.col_quarrytype {
  float: left;
}

.col_quarrytype:last-child::after {
  display: none;
}

.ant-table-wrapper .ant-table-container {
  overflow-x: auto;
}

// .ant-modal.tickectfinan_modal.antmodal_grid.headermodal {
//   width: 100% !important;
//   max-width: 700px;
// }
// .ant-modal.crmform_modal.antmodal_grid.headermodal {
//   width: 100% !important;
//   max-width: 640px;
// }
.linkcompany {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;

  a {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: max-content;
    white-space: nowrap;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: max-content;
    white-space: nowrap;
  }
}

.ant-input-number-affix-wrapper.numberbox {
  border-radius: 5px;
}

.ant-input-number-affix-wrapper.numberbox:hover,
.ant-input-number-affix-wrapper.numberbox:focus,
.ant-input-number-affix-wrapper-focused.numberbox,
.select_Height.ant-select-focused {
  border-color: #586370 !important;
  box-shadow: none;
}

.actionpayable {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: left;
}

.formpayablecontent {
  margin: 0px auto;
  width: 100%;
  max-width: 560px;
}

.payflex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  align-items: center;
  align-content: center;
  justify-content: left;
  margin-bottom: 10px;
}

.payflex span.title_changes {
  margin-right: 10px;
  min-width: 150px;
  justify-content: right;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    min-width: 120px;
  }
}

.payflex .ant-row.ant-form-item {
  margin: 5px 0px;
  width: 100%;
  max-width: 60%;

  @media (max-width: 1199px) {
    max-width: 53%;
  }

  @media (max-width: 480px) {
    max-width: 40%;
  }
}

.payflex .ant-row.ant-form-item .payabledate {
  max-width: 120px;
}

span.payable-title {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.ant-divider.ant-divider-horizontal.divipay {
  margin: 5px 0px;
}

@media only screen and (max-width: 767px) {
  .maintwistednail.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    height: 500px !important;
  }

  .ant-card.ant-card-bordered.cardheightreduc {
    height: auto !important;
  }
}

.btnalertflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.btnalertflex button {
  margin: 10px 5px !important;
}

button.ant-switch.switchcolor.ant-switch-checked {
  background-color: #586370;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap {
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  background-color: #e3e7ea;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard .ant-tabs-nav {
  position: relative;
  margin-bottom: 0px;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard {
  width: 100%;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  width: 100%;
  max-width: 90px;
  justify-content: center;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  min-height: 25px;
  font-weight: 500;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-operations {
  display: none;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab:nth-child(4)
  .ant-tabs-tab-btn {
  margin: 0px 0px;
}

.ant-row.toggletabdd span span[aria-label] {
  margin-right: 0px;
}

.ant-tabs-tab.ant-tabs-tab-active.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab:nth-child(4)
  ~ .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  width: max-content !important;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab:nth-child(4) {
  width: max-content;
  margin: 5px 10px;
}

.ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active:nth-child(4)
  ~ .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  width: max-content !important;
  right: 5px !important;
  left: inherit !important;
  // background-color: transparent !important;
  // box-shadow: none !important;
}

.svgrre svg {
  max-width: 25px;
  height: auto;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem .svgrre svg {
  width: 100%;
  max-width: 25px;
  height: auto;
  margin-right: 10px;
}

.menuitem .svgrre svg g {
  fill: #989da2;
  stroke-width: 30px;
  stroke: #989da2;
}

li.ant-menu-submenu.ant-menu-submenu-vertical.menuitem.ant-menu-submenu-open.ant-menu-submenu-active
  .ant-menu-submenu-title
  .svgrre
  svg
  g {
  fill: #586370;
  stroke-width: 30px;
  stroke: #586370;
}

li.ant-menu-submenu.ant-menu-submenu-vertical.menuitem.ant-menu-submenu-selected
  .ant-menu-submenu-title
  .svgrre
  svg
  g {
  fill: #586370;
  stroke-width: 30px;
  stroke: #586370;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem .svgrre svg g {
  fill: #989da2;
  stroke-width: 30px;
  stroke: #989da2;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-open
  .svgrre
  svg
  g {
  fill: #586370;
  stroke-width: 30px;
  stroke: #586370;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-selected
  .svgrre
  svg
  g {
  fill: #586370;
  stroke-width: 30px;
  stroke: #586370;
}

.svgrre {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.filterbox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: max-content;
}

.filterbox button {
  margin: 0px 10px;
  border-color: #ddd;
  display: flex;
  align-items: center;
}

label.ant-checkbox-wrapper.checktrucktypegrp {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;
}

.ant-checkbox-group.checktrucktype {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-top: 5px;
}

.ant-checkbox-group.checktrucktype
  label.ant-checkbox-wrapper.ant-checkbox-group-item {
  width: 100%;
  max-width: 190px;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
}

.switchtoggle {
  margin-left: auto;
  margin-bottom: 0px;
}

.switchtoggle span {
  font-size: 16px;
  color: #999;
}

.switchtoggle button.ant-switch {
  margin: 0px 5px;
}

.switchtoggle .ant-switch-checked {
  background-color: #586370;
}

.switchtoggle button.ant-switch span.ant-switch-inner {
  color: white;
  font-size: 100%;
}

.kanbanscroll {
  text-align: left;
  word-break: break-all;
}

.quarrytoolgrid {
  text-align: left;
  word-break: break-all;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light
  li.ant-menu-item:hover
  > svg.menuitem.ant-menu-item-icon {
  color: #586370;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light
  li.ant-menu-item:hover
  > span.ant-menu-title-content
  a
  span.menuitem {
  color: #586370;
}
.revenuealy td.ant-table-cell {
  height: 73px;
  white-space: nowrap;
}
@media only screen and (max-width: 1599px) {
  .table_laptopscreen table thead tr th,
  .table_laptopscreen table tbody tr td {
    white-space: nowrap;
  }
}
.uploadtaxexempt {
  margin-bottom: 0px;
}
.uploadtaxexempt .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  margin: 10px 0px !important;
  width: 100%;
  height: 150px;
}

.show-on-mobile {
  @media (min-width: 1025px) {
    display: none !important;
  }
}
.show-on-mobile-ownerOperator {
  @media (min-width: 1025px) {
    display: none !important;
  }
}
.show-on-mobile-tab {
  @media (min-width: 1200px) {
    display: none !important;
  }
}
.show-on-small-desktop {
  @media (max-width: 1199px) {
    display: none !important;
  }
}
.show-on-desktop {
  @media (max-width: 1024px) {
    display: none !important;
  }
}
.show-on-mobile-portrait {
  @media (min-width: 600px) {
    display: none !important;
  }
}

.show-on-desktop-portrait {
  @media (max-width: 599px) {
    display: none !important;
  }
}

.mobile_view_footer {
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: white;
  background: #586370;
  height: 45px;
  z-index: 999;
  align-items: center;
  overflow: x;
}
// .sub-buttons :hover {
//   border-color: red !important;
// }
// .ant-col.sub-buttons a button:hover {
//   background-color: #586370 !important;
// }
// .ant-col.sub-buttons a button:hover span.menuitem_lin {
//   color: white !important;
// }

.main-header-profile {
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  margin-left: auto;
  width: 100%;
  max-width: max-content;
  flex: auto;
}
.final-address-card {
  @media (min-width: 375px) and (max-width: 1024px) {
    height: auto !important;
    max-height: 20vw !important;
    overflow-y: scroll !important;
    width: 100% !important;
    bottom: 10px;
  }
}

.colltable-option {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  border: 1px;
}

.ant-card-body.final-address-card {
  padding: 5px;
}


.form-data-trucking {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
}

.form-data-trucking .trucking-job-data {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0px;
}

.form-data-trucking .trucking-job-data span {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  margin-bottom: 0px;
  color: #000;
  font-weight: 400;
}

.heading_item{
  font-weight: 500 !important;
}
