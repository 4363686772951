.card_virtual.ant-card-bordered {
  box-shadow: 0px 10px 20px #0000000d;
}

.card_virtual .ant-card-head-title {
  color: #232323;
  opacity: 1;
  letter-spacing: 0px;
  padding: 10px 0px 10px;
  font-size: 22px;
  // font-family: Optimistic Text, Helvetica, Helvetica Neue, Arial, sans-serif;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.action_tab {
  width: 175px;
}

.customer_Lead {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 45px;
  border-top: 0px;
  padding: 10px;
}

.customer_leadHeading {
  font-size: 16px;
  font-weight: 600;
  color: #495057;
}

.customer_Lead_data {
  border-left: 1px solid #fff;
  border-top: 0px;
}

.customer_leadHeading.customer_Lead {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 80px !important;
  font-size: 15px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 13px !important;
  }
}

.lead_Name {
  font-size: 15px;
  color: #4e555c;
  //   font-family: "Poppins", sans-serif !important;
  // font-family: system-ui;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

/* width */
.kanbanscroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.kanbanscroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(88, 99, 112, 0.102) !important;
  border-radius: 10px;
}

/* Handle */
.kanbanscroll::-webkit-scrollbar-thumb {
  background: rgba(88, 99, 112, 0.435) !important;
  border-radius: 10px;
}

/* Handle */
.kanbanscroll::-webkit-scrollbar-thumb:hover {
  background: #586370 !important;
  border-radius: 10px;
}

/* width */
.kanbanscrollCard .ant-card-body::-webkit-scrollbar {
  @media (min-width: 375px) and (max-width: 766px) {
    text-align: center !important;
  }
  width: 5px;
  height: 5px;
}

/* Track */
.kanbanscrollCard .ant-card-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(88, 99, 112, 0.102) !important;
  border-radius: 10px;
}

/* Handle */
.kanbanscrollCard .ant-card-body::-webkit-scrollbar-thumb {
  background: rgba(88, 99, 112, 0.435) !important;
  border-radius: 10px;
}

/* Handle on hover */
.kanbanscrollCard .ant-card-body::-webkit-scrollbar-thumb:hover {
  background: #586370 !important;
}

.lead_Email {
  font-size: 12px;
  color: #c1cbd1;
  font-family: "Montserrat", sans-serif;
  //   font-family: system-ui;
  font-weight: 400;
}

.select_crm .ant-select-selection-item {
  width: 140px;
  font-weight: 500;
  font-size: 16px;
}

.select_ticket .ant-select-selection-item {
  font-weight: 500 !important;
  font-size: 24px !important;
}
.select_option .ant-select-item-option-content {
  font-size: 18px !important;
}

.select_crm.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
}

.select_crm.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2.5px !important;
}

.button_Crm {
  height: 40px !important;
  border-radius: 5px !important;
}

.table_crm .ant-table-container table > thead > tr:first-child th:first-child {
  width: 3%;
}

.table_crm,
.table_manageroles,
.ant-table-thead > tr > th {
  background: #e3e7ea !important;
  font-weight: 400;
}

.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}

.col_styling {
  color: "#5a6066";
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.col_styling_pending {
  color: #f5a37f;
}

.stones-tab,
.ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
  padding: 10px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 13px !important;
  }
}

// .tab_Text {
//   color: #91a3ae !i;
// }
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #586370 !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab {
  color: #91a3ae;
  font-weight: 400;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.stones-tab,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  margin-left: 15px !important;
  margin-right: 15px !important;
  @media (min-width: 375px) and (max-width: 1024px) {
    margin-left: 0px !important;
  }
}

.react-grid-item.cssTransforms {
  border-radius: 5px !important;
  // height: 125px !important;
}

.grid_Name {
  font-size: 15px;
  color: #575e64;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.grid_Email {
  // font-size: 14px;
  font-size: 125%;
  color: #bac5cc;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
  margin-top: -3px;
}

.grid_Company {
  // font-size: 15px;
  font-size: 14px;
  color: #5f666c;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
}

.grid_Status {
  text-align: right;
  margin-top: 5px;
  font-size: 14px;
  color: #96a7b2;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.table-crm1 .ant-table-thead > tr > th {
  background: #e3e7ea !important;
}

.background-transparent .ant-drawer-content {
  background: rgba(0, 0, 0, 0.05) !important;
}

.ant-select.ant-select-borderless.select_header.ant-select-single.ant-select-show-arrow {
  height: 100%;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  max-width: 230px;
  width: 100% !important;

  @media (max-width: 991px) {
    font-size: 100% !important;
    max-width: max-content !important;
  }
}

.ant-select.ant-select-borderless.select_header.ant-select-single.ant-select-show-arrow
  .ant-select-selector {
  height: 100%;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.crmheading {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
  justify-content: left;
  padding-left: 5%;

  @media (max-width: 996px) {
    width: 50%;
  }

  @media (max-width: 991px) {
    .ant-select-selector {
      font-size: 100%;
    }
  }
}

@media (max-width: 1030px) {
  .customertablegrp .ant-table-container .ant-table-body {
    width: 100% !important;
    min-width: 1200px;
  }
}

// @media (max-width:991px) {
//   button.ant-btn.ant-btn-default.button_Crm {
//     margin-bottom: 10px;
//   }
// }

.centertxttable {
  justify-content: center;
  display: flex;
  max-width: 100%;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  position: relative;
  padding: 15px;
  overflow-wrap: break-word;
}
