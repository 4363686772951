.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.colbottom {
  margin-bottom: 5px !important;
}

.righttextfilter {
  text-align: right;
}

.righttextfilter span a {
  text-decoration: underline;
  font-size: 14px;
  color: #586370 !important;
}
.filterbottom {
  margin-bottom: 10px;
}
.input-side
  span.ant-input-affix-wrapper.form-input.ant-input-affix-wrapper-borderless {
  display: flex;
  width: 100% !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-side
  span.ant-input-affix-wrapper.form-input.ant-input-affix-wrapper-borderless
  span.ant-input-suffix {
  position: absolute;
  right: 0;
  bottom: -17px;
  font-size: 11px;
}
.input-side span.ant-input-affix-wrapper.form-input.ant-input-affix-wrapper-borderless input.ant-input.ant-input-borderless.form-input {
  width: 100% !important;
  max-width: 100% !important;
  flex: auto;
  height: 35px;
  border-bottom: 1px solid #ddd;
  // color: red;
  // font-weight: bold;
  font-size: 13px;
}
.input-side
  span.ant-input-affix-wrapper.form-input.ant-input-affix-wrapper-borderless {
  display: flex;
  width: 100% !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
  // margin: 5px 0px;
  height: auto !important;
}
.detail-ans.styles {
  border: 1px solid #ddd !important;
  height: auto;
}

.countingspan {
  background-color: #495057;
  color: white;
  position: absolute;
  width: 100%;
  bottom: auto;
  left: 0px;
  max-width: 100%;
  padding: 10px 15px;
}

.counthashkey {
  align-items: center;
  display: flex;
}

.counthashkey svg {
  margin-right: 5px;
}

.ant-form-item-has-error input.ant-input-number.numberictest {
  background: #fff;
  border-color: #ff4d4f;
}
label.ant-checkbox-wrapper.title_changes.check {
  margin: 4px 0;
}

.checkBox_size {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #111827;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

@media (max-width: 400px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.drawerbox
    .ant-drawer-content-wrapper {
    width: 90% !important;
  }
}
