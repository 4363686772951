.col_style_owner {
  color: "#5a6066";
  font-family: 'Montserrat', sans-serif;

  font-weight: 400;
  font-size: 14px;
}

.action_tab {
  width: 175px;
}

.icon-alignmentit {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0px;
  align-content: center;
  width: 100%;
  justify-content: left;
  font-size: 15px;
  cursor: pointer;
}

.icon-alignmentit svg {
  margin: 4px;
}

.ownerdatatable .ant-table-body table {
  @media (max-width: 1280px) {
    width: 100% !important;
    min-width: 1280px !important;
  }
}
.disp_fixed.disp_twistednail {
  margin-left: auto;
  max-width: 100%;
  width: 100%;
  align-items: baseline;
  height: auto;
  padding: 10px 10px;
  background-color: #e3e7ea !important;
}

.disp_fixed.disp_twistednail ul.ant-pagination.pagindistpatch {
  row-gap: 8px;
  max-width: max-content;
  margin: 0px;
  flex-wrap: nowrap;
}
// td.ant-table-cell.col_style_quarry.col_style_quarry222 {
//     padding: 22px 16px !important;
// }
.tnbs_bg {
  background-color: red;
}
