.col_styling_for_system_users {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.table_manageroles
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  width: 50%;
}

.table_system_users
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  width: 25%;
}

.col_styling_system_users {
  //  width: 40%;
  color: "#5a6066";
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.action_tabs {
  width: 75px;
}

.blineboxgrp {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80px;
}

.blinebox {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.systemcard {
  margin: 10px auto;
  max-width: 680px;

  @media (max-width: 992px) {
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .ant-table-wrapper.table_system_users.table_crm .ant-table-body table {
    min-width: 734px !important;
  }
}

@media (max-width: 767px) {
  form#adduserform {
    padding: 0px !important;
  }
}
.input_subCategory {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9 !important;
  width: 100%;
}

.btn_PopconfirmDelete {
  font-size: 20px;
  cursor: pointer;
  border-top: none;
  border-right: 1px solid #c3c3c3 !important;
  border-bottom: none;
  border-left: none;
  border-image: initial;
  padding: 0px;
  text-align: left;
  width: 100%;
  height: auto;
  background: transparent !important;
}

.btn_deleteIcon {
  color: #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  background-color: transparent;
  font-size: 18px !important;
}

.btn_PopconfirmEdit {
  font-size: 20px;
  cursor: pointer;
  border: none;
  padding: 0px;
  text-align: left;
  width: 100%;
  height: auto;
  background: transparent !important;
}

.popover_row {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  align-items: center;
}

.span_text {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 400;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    font-size: 14px !important;
  }
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
}
