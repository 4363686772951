.update-profile-btn {
  width: 100% !important;
  margin: 10px auto !important;
  height: auto !important;
}

.Btn-name {
  font-size: 16px;
  color: #fff;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.update-profile-btn span,
.update-profile-btn span > .ant-upload.ant-upload-select {
  width: 100% !important;
}

.uploadimg_grid {
  margin: 20px auto;
  max-width: 100%;

  @media (max-width: 1199px) {
    max-width: 240px;
  }
}
.image_dispalyFilter {
  display: flex;
  align-items: center;
}
