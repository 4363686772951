.card-dash {
  min-height: 105px;
  border-radius: 10px;
  // width: 221px;
}

.dotDashBoard {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.card-clr-dash {
  width: 44px;
  border-radius: 10px;
}

.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  text-transform: uppercase;
}

.table-dash .ant-table-thead > tr > th {
  position: relative;
  // color: grey;
  font-weight: 400;
  text-align: left;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  padding: 10px;
}
span.amount_type {
  text-align: right;
  // width: 100%;
  min-width: 100px;
  display: inline-block;
  float: right;
}
span.amount_type.revenue {
  text-align: right;
  min-width: 100px;
  display: inline-block;
  float: right;
}
span.amount_type1 {
  text-align: left;
  min-width: auto;
  display: inline-block;
  color: #333;
  float: left;
}
span.dollar_class {
  float: left;
}

.ant-input:placeholder-shown,
.ant-input-number:placeholder-shown,
.ant-picker-input > input:placeholder-shown,
.rc-time-picker-input:placeholder-shown,
.ant-input-number-input:placeholder-shown,
span.ant-select-selection-item {
  text-transform: capitalize;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-open {
  color: #586370;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > i.ant-menu-submenu-arrow {
  color: #586370;
}

li.ant-menu-item.ant-menu-item-selected svg.menuitem.ant-menu-item-icon {
  fill: #586370;
}

li.ant-menu-item.ant-menu-item-selected > span.ant-menu-title-content {
  color: #586370;
}

li.ant-menu-item.ant-menu-item-selected > span.ant-menu-title-content a {
  color: #586370;
}

li.ant-menu-item.ant-menu-item-selected > span.ant-menu-title-content a span {
  color: #586370;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > i.ant-menu-submenu-arrow {
  color: #586370;
}

li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-selected {
  color: #586370;
  background-color: #cdd5df;
}

.grapmapclass {
  overflow: hidden;
  height: 380px;
  padding: 15px;
}

.grapmapclass canvas {
  margin: 15px 0px;
}

.mainlayout {
  margin: 30px;
  overflow: initial;
  padding-top: 70px;
}
h2.heading-big {
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px;
  }
}
.headingcard {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.headingcard a {
  color: #808080;
  font-weight: 500;
}

.ant-card.ant-card-bordered.cardtitlemob .ant-card-head-title {
  text-align: left;
}

@media (min-width: 375px) and (max-width: 1024px) {
  .ant-card-head {
    padding: 0px 12px !important;
  }
  .dashboard {
    margin-top: 2vw;
  }
}

@media (max-width: 992px) {
  .grapmapclass {
    overflow: hidden;
    height: 300px;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .grapmapclass {
    overflow: hidden;
    height: 240px;
    padding: 15px;
  }
  .ant-table-wrapper.table-dash .ant-table-container {
    // dashboard page
    width: 740px;
    margin: 0px auto;
  }
}

@media (max-width: 450px) {
  .ant-card.cardtitlemob .ant-card-head-wrapper {
    flex-wrap: wrap;
  }
  .ant-card.cardtitlemob .ant-card-head-title {
    width: 100%;
    flex: none;
    padding-bottom: 0px;
  }
  .ant-card.cardtitlemob .ant-card-extra {
    width: 100%;
  }
}
.table-dash table_laptopscreen {
  overflow-y: auto;
}

@media (min-width: 375px) and (max-width: 736px) {
  .ant-table-wrapper.table-dash.table_laptopscreen {
    padding: 0px !important;
  }
}

.cardtitlemob_topTrucks {
  margin-right: 5px;
  width: 150px;
  @media (min-width: 375px) and (max-width: 736px) {
    margin-right: 5px;
    width: 150px;
  }
}

.ant-card-head-title {
  flex: none;
}

.ant-table {
  overflow-x: auto;
}

.ant-charts .g2-labels-group text {
  fill: red !important; /* Set the desired text color (e.g., black) */
}
.dashBoard_switch {
  .ant-switch-inner {
    font-size: 14px !important;
  }
  .ant-switch-handle {
    top: 6px !important;
  }
}
