.col_styling_manageroles {
  width: 90%;
  color: "#5a6066";
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.checkBox_data {
  font-size: 16px;
  font-weight: 400;
  font-size: Karla, sans-serif;
  color: #333333;
  margin-left: 10px;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    font-size: 14px;
  }
}

.role_collapse,
.ant-collapse-header {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    font-size: 13px;
  }
}

span.date-dispatch {
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    white-space: nowrap;
    font-size: 14px;
    justify-content: start;
  }
}
.show-on-mobile-download {
  @media (max-width: 574px) {
    display: none !important;
  }
}

.download-button {
  @media (min-width: 375px) and (max-width: 574px) {
    border: none !important;
  }
}

.download-button-icon {
  @media (min-width: 400px) and (max-width: 766px) {
    font-size: 16px !important;
  }
}

.blineboxgrp {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80px;
}

.blinebox {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .ant-modal.managerole_modal {
    width: 90% !important;
  }
}
