.tic-styl {
  padding: 20px;
}

.display_inovice {
  display: flex;
  @media (min-width: 375px) and (max-width: 766px) {
    flex-wrap: wrap;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}

.display_head {
  max-width: 200px !important;
}

.display_val {
  min-width: 100px;
  font-weight: 400;
}

.underscore-text {
  text-align: right !important;
}
.txtinvoiceprt2{
  text-align: right !important;
  margin-right: 20px;
}
.txtinvoiceprt1{
  text-align: right !important;
}
.noteinvice-box {
  margin: 3% auto;
  max-width: 100%;
  width: 100%;
}

.flex_invoice {
  // display: flex;
  // flex-wrap: nowrap;
  // max-width: 100%;
  // width: 100%;
  // margin: 2px auto;
  // justify-content: left;

  font-size: 16px;
  display: table;
  max-width: 100%;
  width: 100%;
  margin: 5px 0px;

  @media (max-width: 380px) {
    // flex-wrap: wrap;
  }
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.table_invoice {
  font-size: 16px;
  font-weight: 500;
}

.flex_invoice b {
  // display: flex;
  // margin: 0px 5px;
  // flex-wrap: wrap;
  // width: 100%;
  // max-width: 135px;
  // white-space: nowrap;
  display: table-cell;
  margin: 0px 5px;
  width: 170px;
  max-width: 100%;
  white-space: nowrap;

  @media (max-width: 380px) {
    max-width: 100%;
  }
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
    width: 52px !important;
  }
}
.flex_invoice b::after {
  content: ":";
  // float: right;
  margin: 0px 10px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.flex_invoice span {
  display: table-cell;
  margin: 0px 5px;
  white-space: nowrap;
  width: auto;
  max-width: 100%;
  // padding-left: 9%;
}
.flex_invoice span b {
  width: 100%;
  display: table;
}
.invoiceamount2 {
  // margin: 10px auto;
  // display: flex;
  // flex-wrap: wrap;
  // max-width: 100%;
  // width: 100%;
  // justify-content: right;
  margin: 10px auto;
  display: table;
  max-width: 100%;
  width: 100%;
  text-align: right;

  @media (max-width: 991px) {
    justify-content: left;
    font-size: 12px !important;
  }
}
.printviewgrp {
  width: 100%;
  overflow-x: auto;
  padding: 10px;
}
.printviewscroll {
  width: 100%;
}

.invoiceamount2 span {
  // display: flex;
  // flex-wrap: wrap;
  // max-width: 100%;
  // width: 100%;
  // margin: 2px 0px;
  // color: rgba(0, 0, 0, 0.5);
  // font-weight: normal;
  // font-size: 14px;
  // justify-content: right;
  display: table-cell;
  max-width: 100%;
  width: 100%;
  margin: 2px 0px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 14px;
  float: right;

  @media (max-width: 991px) {
    justify-content: left;
  }
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
    margin: 1px 0px;
  }
}

// .invoiceamount {
//     margin: 10px auto;
//     display: flex;
//     flex-wrap: wrap;
//     max-width: 100%;
//     width: 100%;
// }

.invoiceamount {
  margin: 10px auto;
  display: table;
  width: 100%;
}

.printview {
  display: table;
  table-layout: auto;
  width: 100%;
  margin: 10px 0px;
  float: left;
}

.headingprint {
  display: table;
  width: 100%;
  float: left;
}
.box_printgrp {
  display: flex;
  width: 100%;
  float: left;
  flex-direction: row;
  align-content: space-between;
}

.box_print {
  display: table;
  float: left;
  width: 100%;
  margin-right: auto;
  text-align: left;
  max-width: 320px;
}
.box_printr {
  display: table;
  float: right;
  width: fit-content;
  margin-left: auto;
  text-align: right;
}
.box_printpara {
  display: table-cell;
  float: left;
  width: 100%;
  margin-left: auto;
  text-align: left;
}
.box_printtable {
  display: table;
  width: 100%;
  float: left;
}
.ant-table-wrapper.invoicebill_table table {
  table-layout: auto !important;
}

.ant-typography.headinginvoice {
  margin-bottom: 5px;
  color: rgb(0, 0, 0);
  text-align: left;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
    text-align: left;
  }
}
h5.ant-typography.headinginvoice.table_invoice {
  text-align: right;
}
.parainvoicetxt {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 2px 0px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 14px;

  @media (max-width: 991px) {
    margin: 10px 0px;
  }
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.invoiceamount1 {
  margin-left: 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}

.invoiceamount1 b {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 0px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  font-size: 15px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.invoiceamount1 span {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 2px 0px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 14px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

span.invoiceft_para {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  margin: 10px auto;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 14px;
  justify-content: left;
  align-content: center;
  text-align: left;
}

.invoiceamount3 {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 260px;
  width: 100%;
}

.printbtn {
  padding: 10px 0px;
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
  max-width: max-content;
  width: 100%;
  gap: 10px;
}
.printStyle {
  padding: 10px 0px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
}

.printStyle button {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.printbtn button {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  line-height: 15px;
  max-width: 100%;
  width: 100%;
  font-size: 110%;
  gap: 10px;
  align-items: center;
}

.accounts_payable.ant-input-number {
  padding: 5px 0px !important;
}
td.tabledatapadding {
  padding: 0px !important;
}
td.tabledatapadding span {
  padding: 12px;
  display: inline-block;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}
.box_printtable.ant-table-content table {
  border: 1px solid #f0f0f0;
  text-align: center;
}
.box_printtable.ant-table-content table th {
  text-align: center;
}
.box_printtable.ant-table-content table td {
  border-right: 1px solid #f0f0f0;
  border-collapse: collapse !important;
  text-align: center;
  // border-bottom: none !important;
}
.box_printtable.ant-table-content table tr:last-child > td {
  border-bottom: none !important;
}
.lefttabledatakk {
  text-align: left !important;
}
.logoview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  img.logo-header {
    max-width: 265px;
    height: auto;
  }
}

.ant-table-content.ant-table.invoicetablennd table thead.ant-table-thead tr th {
  background-color: #e3e7ea !important;
}
.ant-table-content.ant-table.invoicetablennd table tfoot.ant-table-tfoot tr td {
  background-color: #e3e7ea !important;
  font-weight: 600;
}

.fullScreenImageBtn {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  color: red;
}
