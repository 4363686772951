.leaflet-right .leaflet-routing-container.leaflet-routing-container-hide {
  margin-right: 10px !important;
  display: none;
}

.ant-card.ant-card-bordered.truckbox {
  height: 100%;
}

.ant-card.ant-card-bordered.truckbox > .ant-card-body {
  height: 100%;
}

.btn_checked {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none;
  pointer-events: none;
}

.assignTruck_btn {
  background-color: rgb(88, 99, 112);
  border-radius: 5px;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  width: 120px;
  margin: 5px 0px;
  box-shadow: none;
  outline: none;
}

.ant-collapse.mapflexdir {
  overflow-y: auto;
  height: 60vh;
  padding: 0px 10px;
}

.dispatcheadtitle {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
}

.dispatcheadtitle h3 {
  margin: 0px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}

.dispatcheadtitle h3 span {
  margin-left: 15px;
  font-size: 80%;
  font-weight: 400;
  color: #999;
}

.mapflexdir .ant-collapse-item .ant-collapse-header {
  flex-wrap: wrap;
  justify-content: left;
}

.mapflexdir .ant-collapse-item .ant-collapse-header .ant-collapse-extra {
  margin: 0px;
  width: 100%;
  max-width: 100%;
}

.mapflexdir
  .ant-collapse-item
  .ant-collapse-header
  span.anticon.anticon-right.ant-collapse-arrow {
  top: 20%;
}

.mapointsection {
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  justify-content: left;
  padding: 5px 0px;

  p {
    margin: 3px 0px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }
}

.mapflexdir .ant-collapse-item {
  box-shadow: 0px 0px 10px rgba(204, 204, 204, 0.38);
  margin: 15px 0px;
}

.mapflexdir .ant-collapse-item .ant-collapse-content {
  border-top: 1px solid #ddd;
}

.tilsection {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: left;

  h6 {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 5px;
    width: 100%;
  }

  ol {
    width: 100%;
    margin: 0px;
    padding-left: 20px;
  }
}

.assigndrvsectiongrp {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  align-items: center;
  justify-content: left;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
  margin-top: 5px;
}

.assigndrvsectiongrp:last-child {
  border-bottom: none;
}

.assigndrvsection {
  display: flex;
  flex-wrap: wrap;
  max-width: 75%;
  width: 100%;
  margin-bottom: 5px;

  p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
  }

  span {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    color: #999;
    font-weight: 400;
  }
}

.removesect {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;

  span {
    color: #f44336;
    display: flex;
    align-content: space-between;
    font-size: 12px;
    cursor: pointer;
  }
}

.markercluster-map {
  height: 90vh;
}

.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: #f5984e !important;
}

.marker-cluster-medium,
.marker-cluster-large,
.marker-cluster-small {
  // background-color: #f5b9a1 !important;
  background-color: transparent !important;
}

.marker-cluster-small div {
  background-color: #f0cc40 !important;
}

.leaflet-marker-icon.leaflet-div-icon {
  width: 100% !important;
  height: 100% !important;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  // padding: 15px;
  box-sizing: border-box;
  border: none !important;
  margin: 0px !important;
  border-radius: 30px;
}

.truckid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: +99;
    padding: 13px;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 0px;
  }
}

// .truckid::before {
//   content: "▼";
//   position: absolute;
//   // color: #000003ab;
//   bottom: -31px;
//   // transform: rotate(0deg);
//   // left: 5px;
//   font-size: 16px;
// }
.naviconmapss {
  position: relative;

  i {
    position: absolute;
    z-index: -1;
    font-size: 40px;
    left: 0px;
    top: 0px;
  }
}

.quarryName {
  background: #000080;
  border: 2px solid #d2042d;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  min-width: 100px;
}

// .quarry_map_tag {
//   color: #586370;
//   text-align: center;
//   font-weight: bolder;
//   font-size: 20px;
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   align-items: flex-start;
//   margin-top: -8px;
// }

div#multimap:empty {
  display: none;
}

span.nodataenough::before {
  content: "--No Enough Data--";
  color: #ddd;
}
.companydetail_mapempty {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 71vh;
  border: 1px solid #f0f0f0;
  background-color: white;
}
.trucktypeallowedgrid {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  align-items: baseline;
  justify-content: left;
  box-sizing: border-box;
}

.trucktypeallowedgrid h4 {
  width: 100%;
  font-size: 15px;
  margin: 0px;
  font-weight: 500;
  max-width: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p.parapopupjob {
  margin: 0px 5px;
  font-weight: 500;
  width: min-content;
  min-width: max-content;
  color: #666;
  white-space: nowrap;
  display: inline-block;
}
