.truckingMaterial_buttons {
  height: 40px;
  margin: auto 0px;
  border-radius: 5px;
  @media (min-width: 375px) and (max-width: 599px) and (orientation: portrait) {
    padding: 10px;
  }
}

.ownerOperator_button {
  @media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
    padding: 8px;
    max-width: 175px;
  }
  @media (min-width: 480px) and (max-width: 685px) and (orientation: landscape) {
    font-size: 14px;
    padding: 6px;
  }
  @media (min-width: 686px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 14px;
    padding: 6px;
  }
}
.owner-operator-layout {
  justify-content: right;
  @media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
    justify-content: space-between;
  }
}
.filter_button {
  @media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
    padding: 8px;
  }
  @media (min-width: 686px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 14px;
    padding: 6px;
  }
}

.board_button {
  padding: 8px;
  @media (min-width: 375px) and (max-width: 440px) and (orientation: portrait) {
    padding: 8px;
    top: 5px;
  }
  @media (min-width: 686px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 14px;
    padding: 6px;
  }
}
.show-on-desktop-owner {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.customerDatabase_buttons {
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    justify-content: right !important;
  }
}

.button.ant-btn.ant-btn-primary.customer {
  @media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
    margin-top: 2px;
  }
}

.customer-button {
  @media (min-width: 375px) and (max-width: 736px) and (orientation: portrait) {
    margin-top: 5px !important;
  }
}

.addCustomer_button {
  @media (min-width: 375px) and (max-width: 738px) {
    max-width: 15vw;
  }
}
.customerDatabase_search {
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    margin-top: 5px !important;
  }
}

.layout-landscape {
  @media (min-width: 375px) and (max-width: 1025px) and (orientation: landscape) {
    margin-top: 4px !important;
  }
}
.archive-button-ownerOperator {
  @media (min-width: 375px) and (max-width: 738px) {
    font-size: 13px !important;
    max-width: 12vw;
  }
}
.filterButton {
  @media (min-width: 375px) and (max-width: 738px) {
    max-width: 14vw;
  }
}
.filterIcon {
  margin-top: 2px;
  @media (min-width: 375px) and (max-width: 738px) {
    font-size: 20px;
    margin-left: 0px !important;
    margin-top: 2px;
  }
}

.ant-row.layout-landscape {
  justify-content: left !important;
}

.filterIcon-driver-database {
  font-size: 22px;
  margin-left: 8px;
  margin-top: 2px;
  @media (min-width: 375px) and (max-width: 738px) {
    font-size: 18px !important;
    margin-left: 0px;
  }
}

.filterButton-driver-database {
  @media (min-width: 375px) and (max-width: 738px) {
    max-width: 13vw;
  }
}

.excel-driver-database {
  font-size: 14px;
}

.total-layout-quarry-database {
  justify-content: right !important;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    justify-content: space-between !important;
  }
}
.ant-card.ant-card-bordered.cards-top-layout {
  height: 100px;
  align-items: left;
  text-align: left;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    height: 100px;
    align-items: center;
    text-align: center;
  }
}
.upload-ticket {
  justify-content: right;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    justify-content: left;
  }
}
.generate-invoice-button {
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    max-width: 2vw;
  }
  @media (min-width: 375px) and (max-width: 738px) and (orientation: landscape) {
    max-width: 5vw;
  }
}
.rangepicker-invoice {
  @media (min-width: 375px) and (max-width: 1025px) {
    max-width: 100%;
  }
}
.ticket-processing-card {
  @media (min-width: 375px) and (max-width: 1024px) {
    padding: 0px;
  }
}

// .ant-card-body {
//   @media (min-width: 375px) and (max-width: 766px) {
//     padding: 0px;
//   }
// }
.invoice-management-card {
  @media (min-width: 375px) and (max-width: 766px) {
    overflow-x: scroll !important;
    padding: 10px;
  }
}

// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
// .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
//   @media (min-width: 375px) and (max-width: 766px) and (orientation: portrait) {
//     display: block;
//     position: relative;
//     transition: transform 0.3s;
//     // min-width: 40vh;
//     max-width: 100% !important;
//   }
// }
.tabstatus .ant-tabs-nav-list .ant-row {
  flex-wrap: nowrap;
}
.linedivider {
  @media (min-width: 375px) and (max-width: 766px) {
    margin-bottom: 5px;
  }
}

.show-early-pay-discount {
  @media (min-width: 375px) and (max-width: 1025px) {
    margin-top: 10px !important;
  }
}
.filter-button-project {
  margin-bottom: 10px !important;
}
.search-button-project {
  @media (min-width: 375px) and (max-width: 766px) and (orientation: portrait) {
    padding: 5px;
  }
}
.system-users-layout {
  justify-content: right;
  @media (min-width: 375px) and (max-width: 766px) and (orientation: portrait) {
    justify-content: space-between !important;
  }
}

.salesperson-button {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px;
  }
}
.system-settings-layout {
  margin-bottom: 1vw;
  @media (min-width: 375px) and (max-width: 766px) and (orientation: portrait) {
    margin-bottom: 4vw !important;
  }
}

.main-job-details {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.detail-heading-notes {
  font-size: 16px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}
.ticket-upload-card {
  width: 200px !important;
  @media (min-width: 375px) and (max-width: 766px) {
    width: 0px !important;
  }
}

.details-collapse {
  font-size: 12px !important;
  @media (min-width: 375px) and (max-width: 400px) {
    font-size: 11px !important;
  }
}
.details-icon {
  font-size: 12px !important;
  @media (min-width: 375px) and (max-width: 400px) {
    font-size: 11px !important;
  }
}
.enter-invoice-input {
  padding-right: 10px;
  @media (min-width: 375px) and (max-width: 400px) and (orientation: portrait) {
    padding-right: 0px;
  }
}

.trucking-material-layout {
  justify-content: right;
  @media (min-width: 375px) and (max-width: 766px) {
    justify-content: right;
  }
}

.owner-operator-card-head {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}
.owner-operator-card-body {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}
.project-analysis-layout {
  text-align: left !important;
  // @media (min-width: 375px) and (max-width: 766px) {
  //   text-align: center !important;
  // }
}
span.table-font-mobile:empty::before,
span.col_styling:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}
.table-font-mobile {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}
.table-font-mobile-accounts {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}
thead.ant-table-thead {
  font-size: 14px !important;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}
.trucking-details-download {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 22px !important;
  }
}

.sales-person-details {
  @media (min-width: 375px) and (max-width: 766px) {
    width: 120px !important;
  }
}
.no-items-card {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 16px !important;
    padding: 10px !important;
  }
}
.invoice-download-icon {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 25px !important;
  }
}

.button.ant-btn.ant-btn-default {
  @media (min-width: 375px) and (max-width: 766px) {
    width: 80px !important;
  }
}
// .button_Crm {
//   padding-left: 6px!important;

// }
.trucking-filter-icon {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 18px !important;
  }
}
.lead-details-trucking-details {
  @media (min-width: 375px) and (max-width: 766px) {
    width: 80vh !important;
  }
}
.collapse-details-size {
  @media (min-width: 300px) and (max-width: 375px) {
    font-size: 10px !important;
  }
  @media (min-width: 376px) and (max-width: 576px) {
    font-size: 12px !important;
  }
  @media (min-width: 577px) and (max-width: 1024px) {
    font-size: 14px !important;
  }
}

.system-settings-table-head {
  font-size: 15px !important;
  @media (min-width: 577px) and (max-width: 1024px) {
    font-size: 18px !important;
  }
}

.back-button-return-icon {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 20px !important;
  }
}
.back-button-return {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.request-dispatch-button {
  @media (min-width: 375px) and (max-width: 766px) {
    margin-right: 0px !important;
  }
  @media (min-height: 600px) and (max-height: 736px) {
    margin-right: 0px !important;
    font-size: 12px !important;
  }
  @media (min-height: 737px) and (max-height: 750px) {
    margin-right: 0px !important;
    font-size: 14px !important;
  }
}

.popover-mobile {
  @media (min-width: 375px) and (max-width: 766px) {
    max-width: 100px !important;
  }
}

.ticket-uplaod-tags-button {
  @media (min-width: 375px) and (max-width: 766px) {
    height: 30px !important;
    font-size: 14px !important;
    top: 5px !important;
    font-weight: 400 !important;
  }
}

.action-ellipsis-button {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    font-size: 22px !important;
  }
}
.edit-details-button {
  @media (min-width: 375px) and (max-width: 766px) {
    padding: 14px !important;
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    padding: 18px !important;
  }
}
.job-profile-card {
  @media (min-width: 375px) and (max-width: 766px) {
    top: 24px !important;
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    top: 28px !important;
  }
}

.manage-roles-card .ant-card-body {
  @media (min-width: 375px) and (max-width: 766px) {
    padding: 10px !important;
  }
}

.ticket-processing-tags-button {
  width: 140px;
  @media (min-width: 375px) and (max-width: 766px) {
    width: auto !important;
  }
}

.invoice-management-address {
  @media (min-width: 375px) and (max-width: 950px) {
    float: left !important;
  }
}

.dispatch-main-head {
  @media (min-width: 375px) and (max-width: 950px) {
    font-size: 16px !important;
  }
}
.dispatch-tool-map {
  width: 100% !important;
  justify-content: right !important;
  position: absolute;
  top: -71px;
  right: 125px;
  row-gap: 0px;
  @media (min-width: 375px) and (max-width: 1199px) {
    width: 100% !important;
    justify-content: right !important;
    position: absolute;
    top: -71px;
    right: 54px;
    row-gap: 0px;
  }
}
.dispatch-tool-jobMap {
  top: -66px !important;
  right: 45px !important;
}

.dispatch-tool-jobAssignMap{
  top: -49px !important;
  right: 284px !important;
  width: 200px !important;
}

.dispatch-filter-icon {
  margin-right: 20px;
  @media (min-width: 375px) and (max-width: 1199px) {
    border-radius: 5px;
    height: 30px;
    margin-right: -15px !important;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}
span.table-font-mobile-accounts {
  @media (min-width: 375px) and (max-width: 950px) {
    font-size: 12px !important;
  }
}

.home-carousel {
  .rec-carousel-item {
    width: auto;
    margin-right: 0vw;
    height: auto;
  }
  .rec-arrow-left:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-right:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-left,
  .rec-arrow-left:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #586370;
    color: #fff;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-right,
  .rec-arrow-right:focus:enabled {
    // margin-top: 35vw !important;
    background-color: #586370;
    color: #fff;
    @media (max-width: 992px) {
      height: 35px !important;
      width: 35px !important;
      min-width: 35px !important;
      line-height: 0px !important;
      font-size: 16px !important;
    }
  }
  .rec-arrow-left:hover:enabled {
    background-color: #586370;
    color: #fff;
  }
  .rec-arrow-right:hover:enabled {
    background-color: #586370;
    color: #fff;
  }
  .rec-dot {
    background-color: #d9d9d9;
  }
  .rec-pagination {
    margin-top: 0px !important;
    display: none !important;
  }

  .rec-dot_active {
    background-color: #586370;
    box-shadow: 0 0 1px 3px white !important;
  }
  .rec-item-wrapper {
    height: 100%;
  }
}
.likeStyles {
  color: #586370;
  width: 80px;
  border: 1px solid #586370;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  float: right;
  margin-right: 20px;
  height: 30px;
}

.remove_bg .ant-input-group .ant-input {
  background-color: #f0f0f0 !important;
  border: none !important;
  height: 40px !important;
}

.remove_bg .ant-input-group-addon {
  background-color: #f0f0f0 !important;
  border-left: 1px solid #d9d9d9 !important;
}