.status-styling {
  background-color: #b0e6ae;
  color: #0d6e0a;
  border-radius: 4px;
  padding: 3px;
}

.ant-upload.ant-upload-drag {
  height: auto !important;
}

.action_tab {
  width: 175px;
}

.excel_header {
  font-size: 20px;
  font-weight: 500;
  background-color: #b0e6ae;
  // color: #fff;
  font-family: "Montserrat", sans-serif;
}

.select_truckDriver .ant-select-selection-item {
  color: #6f757a !important;
  width: 100px;
  font-weight: 500;
  font-size: 16px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.select_truckDriver.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
}

.select_truckDriver.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2.5px !important;
}

.icon-alignmentit {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0px;
  align-content: center;
  width: 100%;
  justify-content: left;
  font-size: 15px;
  cursor: pointer;
}

.icon-alignmentit svg {
  margin: 4px;
}

@media (max-width: 1030px) {
  .truckdriver_table .ant-table-body table {
    min-width: 1020px !important;
  }
}

.hover_style {
  background: transparent !important;
  padding: 0px 10px;
  width: 175px;

  &:hover {
    background: #e3e7ea !important;
  }
}

.flexprogrssbardd {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: right;
  height: 100%;
  width: 100%;

  .ant-progress.ant-progress-line {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: right;
    width: 100%;
  }
}

.flex-spandd {
  justify-content: left;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  height: 100%;

  span {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: max-content;
    padding: 0px 10px;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: right;
    border-right: 2px solid #ddd;
    @media (min-width: 375px) and (max-width: 766px) {
      font-size: 14px !important;
    }
    @media (min-height: 600px) and (max-height: 736px) {
      font-size: 11px !important;
    }
    @media (min-height: 737px) and (max-height: 750px) {
      font-size: 14px !important;
    }

    p {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: max-content;
      margin: 0px;
      font-size: 100%;
      margin-right: 5px;
      // color: #666666;
      color: #000000;
      @media (min-width: 375px) and (max-width: 738px) {
        font-size: 14px;
      }
    }
  }

  span:last-child {
    border-right: none;
    padding-right: 0px !important;
  }

  span:first-child {
    padding-left: 0px !important;
  }
}

.flex-spandd1 {
  justify-content: right;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  height: 100%;

  .flexprogrssbardd {
    max-width: 220px;
  }
}

.flex-spandd1 .flex-spandd1grp {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: max-content;

  span {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: max-content;
    padding: 0px 10px;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: right;
    border-right: 2px solid #ddd;

    p {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: max-content;
      margin: 0px;
      font-size: 100%;
      margin-right: 5px;
      color: #666666;
    }
  }

  span:last-child {
    border-right: none;
  }
}
.itemmaterial1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
  width: 100%;
  max-width: 320px;
  margin-bottom: 5px;
}
.itemmaterial1 span.anticon {
  margin-right: 5px;
}
.headingdispat {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
    display: block;
    width: max-content !important;
  }
}
.request-dispatch-card-main {
  @media (min-height: 600px) and (max-height: 736px) {
    font-size: 12px !important;
  }
  @media (min-height: 737px) and (max-height: 750px) {
    font-size: 14px !important;
  }
}
