.modal-heading {
  color: #a3a3a3;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0px;
} 

// .sc-cBQgDv {
//   display: none !important;
// }

.modal-para {
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin: 0px;
}
td.ant-table-cell.col_styling.space {
  white-space: nowrap;
}
span.retrun-arrow {
  margin-left: 4px;
  font-weight: 500;
}

.dispatcherCard {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}
.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff;
  display: none;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
  display: none;
}

.dispatcher_date {
  font-weight: bold;
}

.dispatcher_date_dispaly {
  display: flex;
  justify-content: flex-end;
}

.dispatcher_date_dispaly1 {
  display: flex;
  justify-content: flex-start;
}

.jobsDispaly {
  display: flex;
  justify-content: space-evenly;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today {
  background: lightgray;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: lightgray;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value {
  color: black;
}
.flexsheading {
  display: flex;
  align-content: center;
  justify-content: left;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 0px;
  flex-wrap: wrap;
}

.flexsheading p.modal-para {
  width: 100%;
  padding-left: 18px;
  margin-top: 5px;
  font-weight: 400;
  text-transform: capitalize;
}

h2.modal-heading.flexsheading {
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 600;
}

h2.modal-heading.flexsheading span {
  width: 20px;
}

.ant-modal-root .ant-modal-wrap .ant-modal.assignmodalbox {
  width: 95% !important;
}

.headingtruck {
  font-size: 16px;
  //margin: 0px;
  //height: 100%;
  //display: flex;
  //flex-wrap: wrap;
  //align-content: center;
  //justify-content: left;
  color: #a5a5a5;
  text-transform: uppercase;
  font-weight: 500;
}

li.checkbox-truck label.ant-checkbox-wrapper {
  width: 100%;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center !important;
}

li.checkbox-truck label.ant-checkbox-wrapper span.ant-checkbox {
  margin-left: auto;
}

.chackboxbg-truck {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  background-color: #fafafa;
  padding: 10px;
  height: auto;
  align-items: flex-start;
  align-content: flex-start;
  // min-height: 240px;
  margin-top: 10px;
}

.col_styling_status {
  color: "#5a6066";
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.mapdispatch > .mapexample {
  height: 100%;
}

.mapdispatch > .mapexample > div {
  height: 100% !important;
}

.blineboxgrp {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80px;
}

.blinebox {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.icon-alignmentit {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0px;
  align-content: center;
  width: 100%;
  justify-content: left;
  font-size: 15px;
  cursor: pointer;
}

.icon-alignmentit svg {
  margin: 4px;
}

// .mapdispatch {
//    height: 350px;
// }
p.countruck {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-bottom: 0px;
  width: 280px;
  justify-content: right;
}

p.countruck span {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  font-size: 12px;
  width: 100%;
  justify-content: right;
}

.scrlchk {
  overflow-y: auto;
  height: 54vh;
  width: 100%;
  max-width: 100%;
}
.scrlchk.design {
  height: auto !important;
}

.disp_fixed {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 130px;
  align-content: flex-end;
  justify-content: right;
}

.disp_fixed.disp_fixedgrp {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: right;
  height: auto;
  padding: 10px;
  background-color: #e3e7ea !important;
}

.footerdispatch {
  display: flex;
  // flex-wrap: wrap;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
}

.assigntscroll {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  // height: 62vh;
  height: 520px;
}

ul.ant-pagination.pagindistpatch {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

ul.ant-pagination.pagindistpatch > li.ant-pagination-total-text {
  margin: 0px 10px;
  max-width: 80px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

ul.ant-pagination.pagindistpatch li.ant-pagination-item {
  margin: 0px 2px;
  font-weight: 600;
}

@media (max-width: 1030px) {
  .ant-table-wrapper.dispatchtool_table
    .ant-table-container
    .ant-table-body
    table {
    min-width: 1024px !important;
    width: 100% !important;
  }
}

.tr_head {
  background-color: #e3e7ea !important;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  position: relative;
  text-align: left;
  height: 60px;
}

.tr_body {
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  position: relative;
  text-align: left;
  height: 60px;
}

.griddispactoolgrp {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  // border-right: 1px dashed #ddd;
  height: 100%;
  // border-left: 1px dashed #ddd;
  // border-top: 1px dashed #ddd;
}

.griddispactoolgrp b {
  max-width: max-content;
  width: 100%;
  font-size: 14px;
  // height: 100%;
  height: fit-content;
  // min-width: 150px;
}

// .griddispactoolgrp p {
//   max-width: max-content;
//   width: 100%;
//   font-size: 14px;
//   height: 100%;
// }
.griddispactoolgrp p {
  width: 100%;
  font-size: 14px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: list-item;
  max-width: max-content;
  // max-width: 130px;
}

.ant-collapse.ant-collapse-icon-position-left.truckcollapse
  .ant-collapse-content-box {
  padding: 4px !important;
}

.griddispactoolgrp textarea {
  resize: none;
}

.griddispactoolgrp p:empty::before {
  content: "--EMPTY--";
  color: #ddd;
  cursor: text;
}

.truckcollapse .ant-collapse-header.ant-collapse-header-collapsible-only {
  padding: 5px;
}

// .griddispactoolgrp.txtdispatch b {
//   max-width: 33.3% !important;
// }

// .griddispactoolgrp.txtdispatch p {
//   max-width: 66.6% !important;
// }

.griddispactoolgrp.notes b {
  max-width: 15.3% !important;
}

.griddispactoolgrp.notes p {
  // max-width: 500px !important;
  max-width: 100% !important;
}

.griddispactoolgrp.notes {
  flex-wrap: wrap;
}

.ticket_heading {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  // max-width: max-content;
  text-align: left;
  display: block;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    font-size: 14px !important;
    text-overflow: none;
    width: max-content !important;
  }
}

.ticket_heading-mobile {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  max-width: max-content;
  text-align: left;
  display: block;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    width: 100%;
    flex-wrap: nowrap;
    min-width: max-content;
  }
}

.divider-dispatch {
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    min-width: 70vh;
  }
}

.dispatcher-card-layout .ant-card-head {
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    min-width: 70vh;
  }
}

.dispatcher_card {
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%) !important;

  .ant-card-head-title {
    padding: 10px 0px !important;
    width: 100% !important;
  }
  overflow-x: auto !important;
}

.down_arrow {
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  display: flex !important;
  align-items: center !important;
  height: 30px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 12px !important;
  }
}

.text_dispatcher {
  color: #ff0000;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  display: flex !important;
  align-items: center !important;
  height: 26px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.dispatch_btn span.text_dispatcher {
  margin-right: 5px;
}

.dispatch_btn {
  display: flex;
  flex-direction: row-reverse;
}

.dispatcher span.detail-heading {
  color: #000;
  margin-right: 5px;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.dispatcher {
  margin-bottom: 10px;
}

.dispatcher span.detail-ans {
  color: #00000094;
  font-weight: 600;
}
.ant-card.ant-card-bordered.height-card {
  height: 579px;
}
.mapbox-1view {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  align-items: baseline;
  // padding: 0px 5px;
  justify-content: left;
  box-sizing: border-box;
  padding-left: 10px;

  h5 {
    width: 100%;
    font-size: 15px;
    margin: 0px;
    font-weight: 600;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 87px;
    color: #000;

    @media (min-width: 375px) and (max-width: 766px) {
      font-size: 14px !important;
    }
  }

  p.adress-design {
    // margin-right: 147px;
    margin: 0px 0px 0px -100px;
    white-space: nowrap;
  }
  p {
    width: 100%;
    font-size: 15px;
    margin: 0px;
    font-weight: 500;
    max-width: max-content;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666;
  }
}
.mapbox-1view h5.dispatch-data {
  width: 100%;
  font-size: 15px;
  margin: 0px;
  font-weight: 600;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 87px;
  color: #000;
}
.mapbox-1view p.vendor-design {
  width: 100%;
  max-width: 245px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mapbox-1view p.adress-design {
  margin: 0px 0px 0px -100px;
  white-space: nowrap;
  margin-left: auto;
  width: 100%;
  max-width: 390px;
}
.ant-col.tool-align.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
  right: 25px;
}
.mapbox-1views {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  align-items: baseline;
  // padding: 0px 5px;
  justify-content: left;
  box-sizing: border-box;
  padding-left: 10px;

  h5 {
    width: 100%;
    font-size: 15px;
    margin: 0px;
    font-weight: 600;
    max-width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    min-width: 100px;
    text-align: right;

    @media (min-width: 375px) and (max-width: 766px) {
      font-size: 14px !important;
    }
  }

  p {
    width: 100%;
    font-size: 15px;
    margin: 0px;
    font-weight: 500;
    max-width: 100%;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666;
  }
}
.tooltip-container {
  position: relative;
  width: 89%;
}
.tooltip p {
  margin-bottom: 0px;
  padding: 0px;
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  color: #666;
  margin: 0;
  // text-indent: 27px;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
  visibility: hidden;
  z-index: +9;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.mapbox-1view h5::after {
  content: ":";
  float: right;
}
.mapbox-1views h5::after {
  // content: ":";
  float: none;
  margin-left: 7px;
}
.mapbox-trucklist {
  display: flex;
  flex-wrap: nowrap;
  align-content: space-between;
  align-items: center;
  justify-content: left;
  width: 100%;

  p {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    max-width: max-content;
    margin-bottom: 0px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p:last-child {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    max-width: max-content;
    margin-bottom: 0px;
    margin-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h6 {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    max-width: max-content;
    margin: 0px;
    margin-right: 10px;
  }
}

h4.headingdispatcher {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  max-width: max-content;
  margin: 0px auto;
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.notes-dispatch {
  @media (min-width: 375px) and (max-width: 766px) {
    font-size: 14px !important;
  }
}

.maptitlecard .ant-card-head {
  min-height: fit-content !important;
}

.maptitlecard .ant-card-head-title {
  padding: 5px 0px;
}

.flex-ticketprocessing {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  width: 100%;
}

.box-dispatchergrp {
  // border: 1px solid #000;
  // padding: 5px;
  // box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: space-between;
  align-items: flex-start;
  text-align: left;
  justify-content: left;
}

.box-dispatchergrp .box-dispatchergrid {
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  max-width: min-content;
  min-width: 210px;

  p {
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    color: #000;
    font-weight: 500;
    font-size: 15px;

    span {
      font-size: 100%;
      font-weight: 400;
    }
  }
}

.center-box-dispatcher {
  margin: 0px auto;
  max-width: max-content;
}
.box-dispatchergrid.box-dispatchergrid-right p {
  text-align: right;
}
.box-dispatchergrid.box-dispatchergrid-right p .center-box-dispatcher {
  width: 100%;
  max-width: 100%;
}
.box-dispatchergrp .box-dispatchergrid p b.date-information {
  width: 100%;
  display: inline-block;
}
.box-dispatchergrp .box-dispatchergrid p b.date-information span {
  margin-right: 5px;
  display: inline-block;
  width: 100%;
  max-width: 100px;
}
.bor-dispatchertool {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  // padding: 10px;
  // box-sizing: border-box;
  // border: 1px solid #000;
  // border-radius: 15px;
}
.bor-dispatchertool p {
  margin: 0px 0px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
}
.bor-dispatchertool p span {
  float: left;
  width: 100%;
  max-width: 50%;
}
.dispatchertool-grpgrid {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}
.dispatcher-contentgrid {
  display: flex;
  flex-wrap: wrap;
  max-width: min-content;
  width: 100%;
  min-width: 200px;
  margin: 5px;
  @media (min-width: 375px) and (max-width: 738px) and (orientation: portrait) {
    min-width: 100%;
  }
}
.dispatcherCard {
  border: 1px solid #000 !important;
  border-radius: 5px !important;
}
.dispatcherCard.bor-dispatchertoolgrp {
  border: 2px solid #000 !important;
  border-radius: 18px !important;
  margin: 0px 5px;
  width: 100% !important;
}

.dispatcherCard.red-dispatchertoolgrp {
  border: 2px solid #f10b0b !important;
  border-radius: 18px !important;
  margin: 0px 5px;
  width: 100% !important;
}

.dispatcherCard.green-dispatchertoolgrp {
  border: 2px solid #03c503 !important;
  border-radius: 18px !important;
  margin: 0px 5px;
  width: 100% !important;
}

.dispatcherCard.clickCard-dispatchertoolgrp {
  border: 2px solid #ff1a1a !important;
  border-radius: 18px !important;
  margin: 0px 5px;
  width: 100% !important;
}

.table-dispatcharchive {
  margin: 0px auto;
  max-width: 800px;
}
.box-dispatchergrid.box-dispatchergridmore {
  max-width: 68%;
  margin-right: 0px !important;
}
.box-dispatchergrid.box-dispatchergridmore p b.date-information span {
  max-width: max-content;
}
.loop-companybrand {
  display: flex;
  align-content: baseline;
  flex-wrap: wrap;
  width: 100%;
}
.companygrid-boxggr {
  display: block;
  max-width: max-content;
  width: 100%;
}
.popovermoregrid {
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  width: 100%;
  margin-left: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.checkingreport {
  width: 100%;
  max-width: 100%;
  float: left;
}
.checkingreport label.ant-checkbox-wrapper {
  float: left;
  width: max-content;
  margin-top: 3%;
}
.checkingreport .ant-card.ant-card-bordered.ant-card-hoverable.dispatcherCard {
  float: right;
  width: 96%;
}
.dispatch_archive .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.dots {
  font-size: 30px;
  color: grey;
  float: right;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 40px;
  @media (min-height: 600px) and (max-height: 736px) {
    font-size: 15px !important;
  }
  @media (min-width: 375px) and (max-width: 766px) {
    margin-left: 10px !important;
    font-size: 15px !important;
  }
  @media (min-height: 737px) and (max-height: 750px) {
    font-size: 18px !important;
  }
}

.collapse_Padding .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.total_Jobs {
  text-transform: capitalize;
  color: #586370;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: bold;
  position: relative;
  top: 45px;
  left: 15px;
}
.jobs {
  text-transform: capitalize;
  font-size: 18px;
  font-family: sans-serif;
}
