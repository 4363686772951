.col_styling_finance {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-picker .rc-time-picker-input {
  height: 40px;
}

.tickectfinan_modal {
  .ant-modal-content {
    top: -90px !important;
  }
}
.btn-styl {
  color: white;
  border-radius: 5px;
  // height: 40px;
  // width: 120px;
  height: auto;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border: none;
  box-shadow: none;
}

.blineboxgrp {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80px;
}
.tabstatus.filter .ant-tabs-tab {
  margin-left: auto;
}
.tabstatus.filter {
  margin-top: 0px;
}
.tabstatus.filter auto.ant-tabs-nav-operations {
  display: none !important;
}
.tabstatus.filter .ant-tabs.ant-tabs-top.tabversi.tabversi-dashboard {
  width: 100%;
  height: 55px;
  margin-top: 13px;
}

.blinebox {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.pdfviewcls .react-transform-wrapper {
  width: 100%;
  max-width: 100%;
}
.pdfviewcls .react-transform-wrapper .react-transform-component {
  width: 100%;
  max-width: 100%;
}
.pdfviewcls .react-transform-wrapper .react-transform-component embed {
  width: 100%;
  margin: 0px auto;
}

@media (max-width: 1199px) {
  .ant-table-wrapper.financeticket_table
    .ant-table-container
    .ant-table-body
    table {
    min-width: 83rem !important;
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .ant-table-wrapper.financeinvoice_table .ant-table-body table {
    min-width: 620px !important;
    width: 100% !important;
  }
}

.ticket_uplaod {
  color: gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.ant-switch.ant-switch-checked[aria-checked="true"] {
  background-color: #586370;
}

.ticketUpload_switch {
  .ant-switch-inner {
    width: 80px !important;
  }
  .ant-switch-handle {
    top: 5px !important;
  }
}

.ticketProcessing_tagStatus {
  background: transparent !important;
  border: none;
  height: 40px;
  font-size: 16px !important;
  border-radius: 5px;
  padding: 0px;

  .span_btn{
    font-weight: 600;
    font-family: Poppins, sans-serif;
    font-size: 16px;
  }
}
.pending_review_btn{
  height: 40px;
  background-color: rgb(88, 99, 112) !important;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: fit-content;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
}

.invoice_approve_btn{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  font-weight: 600;
}
.dataInput_btn {
  height: 40px;
  background-color: rgb(247, 141, 141) !important;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: fit-content;
  color: rgb(255, 255, 255) !important;
}
.ticket_heading_title_card {
  margin: 20px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0px 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid gray;
  text-align: left;
  row-gap: 0px;

  .ticket_head_span{
    font-size: 18px;
    color: rgb(116, 116, 116);
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }
  .ticket_p_tag {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0px;
  }
}
